import React, { useEffect, useState } from "react";
import { DialogCCListMKT } from "../DialogCCListMKT/DialogCCListMKT";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import { WasteValidationsContainer } from "@/components/molecules";
import { FilterPerWeekMonthController } from "../FilterPerWeekMonthController/FilterPerWeekMonthController";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { ActivitiesDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import { GraphicsType, OverviewItem } from "../OverviewItem/OverviewItem";
import { FormFilterGraph, useMKTActivityData, usePagination } from "@/hooks";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/shadcn/ui/popover";
import { Info } from "lucide-react";
import { FieldPath } from "react-hook-form";

type dataGraphicsType = {
  [key: string]: {
    name_graphic_data: string;
    nameFilter: FieldPath<FormFilterGraph>;
    title: string;
    subtitle?: string;
    span?: number;
    graphicType?: GraphicsType;
    week_date_filter?: boolean;
    infoButton?: JSX.Element;
    monthWeekFilter?: FieldPath<FormFilterGraph>;
  }[];
};
const dataGraphicsActivityApp: dataGraphicsType = {
  validacion_de_residuos: [
    {
      title: "Usuarios TyC vs Usuarios Activos",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
    },
    {
      title: "Status validaciones Totales VS Validaciones Incompletas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">
                  Validaciones incompletas:{" "}
                </label>
                contempla únicamente las búsquedas que no fueron concretadas en
                una actividad de reciclaje. Un estado de búsqueda puede mutar a
                los Totales y se resta de esta pila de datos*
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Status Validaciones",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Conteo de KG con validaciones ACEPTADAS",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Centros de acopio con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Centros de acopio con más kg en validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos VS KG (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  escaneo_productos: [
    {
      title: "Historial de escaneos",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
    },
    {
      title: "SKU's mas escaneados",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">
                  Validaciones incompletas:{" "}
                </label>
                contempla únicamente las búsquedas que no fueron concretadas en
                una actividad de reciclaje. Un estado de búsqueda puede mutar a
                los Totales y se resta de esta pila de datos*
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Categorías con más escaneos (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Residuos con más escaneos (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Marcas más escaneadas (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Productos más escaneados (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  take_photo: [],
  general: [],
};

export const ComplementData = ({
  activity,
  marginx,
}: {
  activity: ActivitiesDatum;
  marginx?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleIsOpen() {
    setIsOpen(!isOpen);
  }
  const pagination = usePagination(10);
  const { control_filters, reset_filters, stats } = useMKTActivityData([], []);
  return (
    <>
      {/*<TableCardWastes handleIsOpen={handleIsOpen} />*/}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5 mt-5">
        {dataGraphicsActivityApp[activity.activity_type].map((item) => (
          <WasteValidationsContainer
            className={`bg-admins-centers bg-opacity-15 col-span-${
              item.span ?? 1
            } mx-${marginx ? 3 : 0}  `}
            key={`graphicCell_${item.title + item.name_graphic_data}`}
            infoButton={item.infoButton}
            title={item.title}
            subtitle={item.subtitle}
            borderVariant={"default"}
            filter={
              item.week_date_filter ? (
                <FilterPerWeekMonthController
                  name_checkbox={
                    item.monthWeekFilter ?? "filterMonthWeek.total"
                  }
                  name_date={item.nameFilter}
                  control={control_filters}
                />
              ) : (
                <FilterPerYearController
                  name={item.nameFilter}
                  control={control_filters}
                />
              )
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={
                // generateGraphic(
                //   stats_collection_centers as any,
                //   item.name_graphic_data,
                //   false,
                //   true
                // ) as any
                undefined
              }
            />
          </WasteValidationsContainer>
        ))}
      </div>
      <DialogCCListMKT
        cc_data={undefined}
        pagination={pagination}
        isOpen={isOpen}
        onClose={handleIsOpen}
      />
    </>
  );
};
