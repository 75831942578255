import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import { BadgeInfoInterface, dataGraphicsType } from "@/interfaces";
import { WasteValidationsContainer } from "@/components/molecules";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { Control, UseFormWatch, useWatch } from "react-hook-form";
import { useGraphicsData } from "@/hooks";
interface SearchersTabInterface {
  onlyCards: boolean;
}
const dataGraphicsSearcherWeb: dataGraphicsType = [
  {
    title: "Búsquedas por sección en WEB",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por Ubicación (Usuarios únicos)",
    subtitle: "*Estado",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por Ubicación (Usuarios únicos)",
    subtitle: "*Municipio",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsqueda por residuos en Filtros WEB (TOP 10)",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
];

const dataGraphicsSearcherApp: dataGraphicsType = [
  {
    title: "Búsquedas por sección en App",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedaen en APP",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por Ubicación (Usuarios únicos)",
    subtitle: "*Estado",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por Ubicación (Usuarios únicos)",
    subtitle: "*Municipio",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsqueda por residuos en Filtros  de usuarios úncos (TOP 10)",
    subtitle: undefined,
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por edad usuarios únicos",
    subtitle:
      "*Cualquier búsqueda por insignia o  residuo asociado a insignia ",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "validation_center_types",
  },
  {
    title: "Búsquedas por género usuarios únicos",
    subtitle:
      "*Cualquier búsqueda por insignia o  residuo asociado a insignia ",
    nameFilter: "graphsYear.year_history",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "validation_center_types",
  },
];

export const SearchersTab = ({ onlyCards }: SearchersTabInterface) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_recycler",
      "enterprises",
      "brands",
      "waste_categories",
      "wastes",
      "waste_category_enterprises",
      "waste_enterprises",
    ],
    keysYear: [
      "year_history",
      "enterprise_waste_categories",
      "enterprise_wastes",
    ],
  });
  return (
    <TabsContent
      value="searches"
      className="w-full grid grid-cols-2 gap-4 px-3"
    >
      <p className="text-admins-text-active font-bold text-2xl col-span-2">
        WEB
      </p>
      <Separator className="col-span-2" orientation="horizontal" />
      {dataGraphicsSearcherWeb.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
      <p className="text-admins-text-active font-bold text-2xl col-span-2">
        APP
      </p>
      <Separator className="col-span-2" orientation="horizontal" />
      {dataGraphicsSearcherApp.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
    </TabsContent>
  );
};
