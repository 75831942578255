import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  Waste_Register,
} from "@/interfaces";
import { dataServices } from "./dataServices";
import { useWastesData } from "./useWastesData";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";

export const useDataWaste = (per_page: number) => {
  const { data } = useSession();

  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(per_page);

  const { wastes, wastes_categories } = useWastesData(data);

  const { data: requestData, refetch } = useQuery({
    queryKey: ["wastes"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/wastes",
            params: {
              per_page: pageSize,
              page: currentPage,
              "include_waste_category_wastes.waste_categories": true,
              "include_waste_informations.waste_properties": true,
              sort: "name",
            },
            apiVersion: "v2",
            token: data?.user?.token ?? undefined,
          })
        : null;
      const response: Waste_Register[] = results.data;
      return { data: response, meta: { ...results.meta } };
    },
  });

  useEffect(() => {
    if (requestData) {
      setTotalPages(requestData?.meta?.total_pages);
      setTotal(requestData?.meta?.total_count);
    }
  }, [requestData]);

  function forceRefetchProducts() {
    setCurrentPage(1);
    refetch();
  }

  useEffect(() => {
    forceRefetchProducts();
  }, [
    pageSize,
  ]);

  async function onSubmitDeleteProduct(id_waste: string) {
    try {
      await dataServices({
        url: `/wastes/${id_waste}`,
        requestType: "DELETE",
        token: data?.user?.token ?? undefined,
      });
      toast({
        variant: "success",
        title: " Eliminado con ÉXITO",
        description: `Se ha ELMININADO con Éxito`,
      });

      forceRefetchProducts();
    } catch (error) {
      toast({
        variant: "destructive",
        title: " Error: No se ha podido Eliminar",
        description: `Error al querer actualizar la información, intentelo más tarde.`,
      });
      forceRefetchProducts();
    }
  }

  return {
    data_wastes: requestData ?? undefined,
    pagination_table: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    wastes,
    wastes_categories,
    onSubmitDeleteProduct,
  };
};
