import { Button } from "@/components/atoms/shadcn/ui/button";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { useCCInfoTable } from "@/hooks";
import { Session } from "next-auth";
import { useEffect, useState } from "react";
import { DialogCCListMKT } from "../DialogCCListMKT/DialogCCListMKT";

export const WasteCollectionCenterTable = ({
  isActive,
  waste_id,
}: {
  isActive: boolean;
  waste_id?: string;
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [type_cc, setType_cc] = useState("centro_acopio");

  const {
    requestData: cc_per_waste,
    pagination: cc_pagination,
    forceRefetchCCInventory: cc_forceRefetchCCInventory,
  } = useCCInfoTable({
    per_page: 10,
    waste_id: waste_id,
    type_cc: "centro_acopio",
    isActive: isActive,
  });

  const {
    requestData: gp_per_waste,
    pagination: gp_pagination,
    forceRefetchCCInventory: gp_forceRefetchCCInventory,
  } = useCCInfoTable({
    per_page: 10,
    waste_id: waste_id,
    type_cc: "punto_verde",
    isActive: isActive,
  });

  useEffect(() => {
    if (waste_id !== "" && isActive) {
      gp_forceRefetchCCInventory();
      cc_forceRefetchCCInventory();
    }
  }, [waste_id]);

  return (
    <>
      <div className="overflow-y-auto max-h-96 grid grid-cols-3 justify-center gap-2 text-center items-center">
        <p>Tipo de centro</p>
        <p className="">No.</p>
        <p className="">Detalle</p>
        <Separator orientation="horizontal" className=" col-span-3" />
        <p>Punto verde</p>
        <p className="">{gp_pagination.total}</p>
        <Button
          variant={"adminUnderlineGhost"}
          onClick={() => {
            setType_cc("punto_verde");
            setIsOpenDialog(true);
          }}
        >
          Ver Detalle
        </Button>
        <Separator orientation="horizontal" className=" col-span-3" />
        <p>Centro de Acopio</p>
        <p className="">{cc_pagination.total}</p>
        <Button
          variant={"adminUnderlineGhost"}
          onClick={() => {
            setType_cc("centro_acopio");
            setIsOpenDialog(true);
          }}
        >
          Ver Detalle
        </Button>
        <Separator orientation="horizontal" className=" col-span-3" />
      </div>
      <DialogCCListMKT
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(!isOpenDialog)}
        pagination={type_cc == "centro_acopio" ? cc_pagination : gp_pagination}
        cc_data={type_cc == "centro_acopio" ? cc_per_waste : gp_per_waste}
      />
    </>
  );
};
