import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { WasteValidationsContainer } from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { dataGraphicsType } from "@/interfaces";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/shadcn/ui/popover";
import { Info } from "lucide-react";
import { dataServices } from "@/hooks";
import { formatData } from "@/shared/functions/formData";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";

type GeneralGraphicTabProps = {
  session: Session | null;
  isActive: boolean;
  waste_id: string;
};

const dataGraphGeneral: dataGraphicsType = [
  {
    title: "Historial de validaciones por tipo de centro",
    subtitle: undefined,
    nameFilter: "graphsYear.year_filter",
    span: 2,
    graphicType: "vertical_bar_comparative",
    name_graphic_data: "validation_center_types",
    infoButton: (
      <Popover>
        <PopoverTrigger>
          <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
        </PopoverTrigger>
        <PopoverContent className=" bg-admins-input-disabled_bg">
          <div className="flex flex-col justify-between  text-admins-text-active ">
            <p className="fs-6 mt-3 mt-lg-0 text-justify">
              <label className="font-extrabold">Búsquedas: </label>
              Contempla todas las búsquedas del residuo de todas las secciones
              (App mapa, App home, Web).
            </p>
            <p className="fs-6 mt-3 mt-lg-0 text-justify">
              <label className="font-extrabold">Validaciones: </label>
              Contempla el No. de validaciones aprobadas que incluyen el
              residuo.
            </p>
            <p className="fs-6 mt-3 mt-lg-0 text-justify">
              <label className="font-extrabold">Escaner: </label>
              No. de escaneos que coinciden con el residuo.
            </p>
            <p className="fs-6 mt-3 mt-lg-0 text-justify">
              <label className="font-extrabold">Compras: </label>
              No. de veces que el residuo fue “comprado”, es decir No. total de
              veces que el residuo fue parte de un ticket de compra.
            </p>
            <p className="fs-6 mt-3 mt-lg-0 text-justify">
              <label className="font-extrabold">Registro: </label>
              No. de registros de Sku s asociados al residuo. Contempla
              únicamente los registros de usuarios.
            </p>
          </div>
        </PopoverContent>
      </Popover>
    ),
  },
];

export const GeneralGraphicTab = ({
  session,
  isActive,
  waste_id,
}: GeneralGraphicTabProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [],
    keysYear: ["year_history"],
  });

  const { data: stats, refetch } = useQuery({
    queryKey: ["test_graph", waste_id],
    enabled: session?.user?.token && isActive ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? generateData(session?.user?.token, waste_id)
        : null;
    },
  });

  const generateData = async (token: string, id: string) => {
    toast({
      title: "Se ha solicitado la información",
      description: `Espera un momento en lo que la información es actualizada`,
      variant: "warning",
    });
    const response = await dataServices({
      url: `/wastes/${id}/stats`,
      params: {
        year: years.year_history,
        type: "general",
      },
      token,
    });
    const formattedData = formatData(response.data);
    return formattedData;
  };

  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="General">
      {/* <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-2 px-5 py-2 gap-4 my-14">
        {dataGraphGeneral.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${
              item.span == 1 ? "" : "col-span-1 lg:col-span-2"
            } bg-admins-centers bg-opacity-15`}
            title={`${item.title}`}
            infoButton={item.infoButton}
            filter={
              <FilterPerYearController
                name={"graphsYear.year_history"}
                control={control}
              />
            }
          >
            {stats && item.graphicType && (
              <OverviewItem
                graphicType={`${item.graphicType}`}
                data={generateGraphic(
                  stats,
                  "validation_center_types",
                  false,
                  true
                )}
              />
            )}
          </WasteValidationsContainer>
        ))}
      </div>
    </TabsContent>
  );
};
