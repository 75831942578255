import { ColumnDef } from "@tanstack/react-table";
import { CCInventory } from "@/interfaces";
import { EyeIcon, PencilIcon, Star, Trash2Icon } from "lucide-react";
import { Switch } from "../atoms/shadcn/ui/switch";

type columnCCInventoryProps = {
  handleCloseConfirm: (id: string) => void;
  handleCloseComments: (data: CCInventory) => void;
  handleEditCC: (data: CCInventory) => void;
  handleActiveCC: (dataProduct: CCInventory) => Promise<void>;
};

export const columnCCInventory = ({
  handleCloseConfirm,
  handleCloseComments,
  handleEditCC,
  handleActiveCC,
}: columnCCInventoryProps): ColumnDef<CCInventory>[] => {
  return [
    {
      accessorKey: "delete",
      header: "",
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2Icon
          className="cursor-pointer hover:text-admins-button-red"
          onClick={() => handleCloseConfirm(row.original.id)}
        />
      ),
    },
    {
      accessorKey: "name",
      header: "Nombre/ID",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p className="font-bold">{row.original.name}</p>
          <p>ID: {row.original.id}</p>
          {row.original?.stars && (
            <div className="flex flex-row justify-center w-full gap-4">
              <p>{row.original?.stars.toFixed(1)}</p>
              <Star className="text-ecolana-lightGreen" />
            </div>
          )}
        </div>
      ),
    },
    {
      accessorKey: "state_name",
      header: "Dirección",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original?.state_name}</p>
        </div>
      ),
    },
    {
      accessorKey: "type_cc_name",
      header: "Tipo",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original.type_cc_name}</p>
        </div>
      ),
    },
    {
      accessorKey: "wastes",
      header: "Residuos",
      enableSorting: false,
      cell: ({ row }) => (
        <p>
          {row.original?.wastes.length > 0
            ? `${row.original?.wastes
                .map((item) => item.waste_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "campaigns",
      header: "Campañas Asociadas",
      enableSorting: false,
      cell: ({ row }) => (
        <p>
          {row.original?.campaigns.length > 0
            ? `${row.original?.campaigns.map((item) => item.name).join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "actions",
      header: "Acciones",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap justify-around gap-4">
            <Switch
              variantBG={"adminGreen"}
              checked={row.original.active}
              onCheckedChange={() => handleActiveCC(row.original)}
            />
            <PencilIcon onClick={() => handleEditCC(row.original)} />
            <EyeIcon onClick={() => handleCloseComments(row.original)} />
          </div>
        );
      },
    },
  ];
};
