import { useEffect } from "react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { dataServices } from "./dataServices";
import { getDataToCCInventory } from "./useCCInventory";
import { RowCCInventory } from "@/interfaces";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import { useSession } from "next-auth/react";

export const useCCInfoTable = ({
  per_page,
  waste_id = "",
  type_cc,
  isActive,
}: {
  per_page: number;
  waste_id?: string;
  type_cc?: string;
  isActive: boolean;
}) => {
  const { data: session } = useSession();
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
    total,
  } = usePagination(per_page);

  const {
    data: requestData,
    refetch,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [type_cc, currentPage, pageSize, waste_id],
    enabled: session?.user?.token && waste_id !== "" && isActive ? true : false,
    queryFn: async () => {
      const results = session?.user?.token
        ? await dataServices({
            url: "/collection_centers",
            params: {
              per_page: pageSize,
              page: currentPage,
              show_inactive: false,
              include_wastes: true,
              include_collection_center_wastes: true,
              include_address: true,
              "waste_ids[]": waste_id,
              center_type: type_cc,
            },
            deserialize: true,
            token: session?.user?.token ?? undefined,
          })
        : null;
        // console.log(results)
      const data_CC: RowCCInventory = getDataToCCInventory(results.data);
      // console.log({ data_CC_table: data_CC });
      return { data: data_CC, meta: { ...results.meta } };
    },
  });

  useEffect(() => {
    if (requestData) {
      setTotalPages(requestData.meta.total_pages);
      setTotal(requestData.meta.total_count);
    }
  }, [requestData]);

  function forceRefetchCCInventory() {
    setCurrentPage(1);
    refetch();
    // console.log({
    //   msg: "forceRefetchCCInventory: ",
    //   isActive,
    //   pageSize,
    //   waste_id,
    //   type_cc,
    // });
    toast({
      title: "Estamos buscando los Centros de Acopio",
      description: `Se estan cargando los centros de acopio con los parametros ingresados, espere un momento por favor Dialog`,
      variant: "warning",
    });
  }

  useEffect(() => {
    waste_id !== "" && isActive && forceRefetchCCInventory();
  }, [pageSize, waste_id]);

  return {
    requestData,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
      total,
    },
    forceRefetchCCInventory,
  };
};
