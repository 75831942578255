import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import React, { useEffect } from "react";
import { WastesItems } from "./WasteCarouselItem";
import { Waste } from "@/interfaces";

export const WasteCarousel = ({
  wastes,
  setActive_waste,
  active_waste,
}: {
  wastes?: Waste[];
  setActive_waste: (id_waste: string) => void;
  active_waste: string;
}) => {
  const [api, setApi] = React.useState<CarouselApi>();

  useEffect(() => {
    if (!api) {
      return;
    }

    const waste_selected_index = wastes?.findIndex(
      (uni_waste) => uni_waste.id == active_waste
    );
    waste_selected_index !== undefined
      ? api.scrollTo(waste_selected_index,false)
      : null;
  }, [active_waste]);

  return (
    <div className="flex w-full justify-center lg:px-16 px-10 items-center">
      <Carousel
        setApi={setApi}
        opts={{ loop: true, align: "start" }}
        className="flex w-full "
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          <WastesItems
            wastes={wastes}
            active_waste={active_waste}
            setActive_waste={setActive_waste}
          />
        </CarouselContent>
        <CarouselPrevious className="bg-white fill-white" />
        <CarouselNext className="bg-white fill-white" />
      </Carousel>
    </div>
  );
};
