import { CustomTag, SelectWithSearch } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import { Searcher, WasteValidationsContainer } from "@/components/molecules";
import { dataServices } from "@/hooks";
import { CollectionCenterResponse } from "@/interfaces";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { WasteCollectionCenterTable } from "./WasteCollectionCenterTable";
import { serialize } from "object-to-formdata";
import { useDebounceCallback } from "usehooks-ts";

interface CC_Filter {
  search: string;
  wastes: string[];
  cc_id: string;
  cc_array: { id_cc: string; name: string; _destroy: boolean }[];
}

export const WasteCollectionCenterCard = ({
  isRegister = false,
  isSaveData,
  data_session,
  isActive,
  waste_id,
}: {
  isRegister?: boolean;
  isSaveData?: boolean;
  data_session: Session | null;
  isActive: boolean;
  waste_id?: string;
}) => {
  const { control, watch, reset, setValue, getValues, handleSubmit } =
    useForm<CC_Filter>({
      defaultValues: {
        search: "",
        wastes: [],
        cc_id: "",
        cc_array: [],
      },
    });

  const { data: cc_totals } = useQuery({
    queryKey: ["collection_center_options", watch("search")],
    enabled:
      data_session?.user?.token && isActive && watch("search") !== ""
        ? true
        : false,
    queryFn: async () => {
      const results = data_session?.user?.token
        ? await dataServices({
            url: "/collection_centers",
            params: {
              per_page: 40,
              show_inactive: false,
              search: watch("search") || "",
              // center_type: "punto_verde" || "",
            },
            deserialize: true,
            token: data_session?.user?.token ?? undefined,
          })
        : null;
      // console.log({ cc_results: results.data });
      const data_cc: CollectionCenterResponse[] = results.data ?? [];
      return data_cc;
    },
    throwOnError(error, query) {
      toast({
        title: "Ha ocurrido un error.",
        description: `Lo siento, ha surgido un error al obtener los Centros de Acopio, favor de intentarlo más tarde`,
        variant: "destructive",
      });
      console.log(error);
      return false;
    },
  });

  const {
    fields: fields_cc,
    append: append_cc,
    remove: remove_cc,
    update: update_cc,
  } = useFieldArray({
    name: "cc_array",
    control: control,
  });

  async function onSubmitNewCC(dataCC: CC_Filter) {
    const object_to_update = {
      waste: {
        collection_center_wastes_attributes: dataCC.cc_array.map((uni_cc) => {
          return {
            id: undefined,
            collection_center_id: uni_cc.id_cc,
            _destroy: uni_cc._destroy,
          };
        }),
      },
    };
    const formData = serialize(object_to_update);
    const dataResponse = await dataServices({
      url: `/wastes/${waste_id}`,
      requestType: "PATCH",
      apiVersion: "v2",
      body: formData,
      contentType: "multipart/form-data",
      token: data_session?.user?.token ?? undefined,
    });
    reset();
  }

  return (
    <>
      <WasteValidationsContainer
        title={"Centros de Acopio Asiciados"}
        className="w-full"
      >
        <div className="w-full grid grid-cols-2 gap-7 justify-center p-4">
          <div className="flex flex-col w-full gap-3">
            <Label
              htmlFor="search_cc"
              className="w-full text-admins-text-active text-center"
            >
              Buscar el nombre de el centro de Acopio
            </Label>
            <Controller
              name={"search"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectWithSearch
                  placeHolder={"Centro de Acopio"}
                  value=""
                  onChangeInput={onChange}
                  setValue={(select: string) => {
                    if (
                      !fields_cc.some((uni_waste) => uni_waste.id_cc == select)
                    ) {
                      const cc_selected = cc_totals?.find(
                        (uni_cc) => uni_cc.id == select
                      );
                      append_cc({
                        id_cc: select,
                        name: cc_selected?.name ?? "",
                        _destroy: false,
                      });
                    }
                  }}
                  selectedItems={cc_totals}
                />
              )}
            />

            <div className="w-full flex flex-wrap max-h-72 overflow-y-auto gap-2">
              {fields_cc.map((uni_cc, index) => (
                <Controller
                  key={`cc_array.${index}.tag_cc_${uni_cc.id_cc}`}
                  name={`cc_array.${index}._destroy`}
                  control={control}
                  render={({ field: { name, onChange, value } }) => {
                    return !value ? (
                      <CustomTag
                        bg_color={"white"}
                        colorIcon={"black"}
                        onClick={() => onChange(true)}
                        className="cursor-pointer w-fit h-fit"
                        tag2Show={uni_cc.name}
                        key={`customTag_${index}`}
                      />
                    ) : (
                      <></>
                    );
                  }}
                />
              ))}
            </div>
          </div>
          {!isRegister && (
            <WasteCollectionCenterTable
              waste_id={waste_id}
              isActive={isActive}
            />
          )}
        </div>
        {isSaveData && (
          <Button
            variant={"adminGreen"}
            className="justify-self-center text-xl mt-5"
            onClick={handleSubmit(onSubmitNewCC)}
          >
            Guardar
          </Button>
        )}
      </WasteValidationsContainer>
    </>
  );
};
