import { CarouselItem } from "@/components/atoms/shadcn/ui/carousel";
import { BadgeCampaignCard } from "@/components/molecules";
import { CampaignsWithDetailsDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import React, { Dispatch, SetStateAction } from "react";

export const CarouselCampaignsItem = ({
  campaigns = [],
  setValue,
  value,
}: {
  campaigns: CampaignsWithDetailsDatum[];
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  return campaigns.map((campaign, index) => (
    <CarouselItem
      key={campaign.id}
      className={`
            ${
              campaign.campaign_type === "app_web"
                ? "basis-12/12 lg:basis-4/12 min-w-fit"
                : "basis-6/12 lg:basis-3/12 min-w-fit"
            }`}
      onClick={() => {
        setValue(campaign.id);
      }}
    >
      <BadgeCampaignCard
        campaign={campaign}
        isSelected={value == campaign.id}
      />
    </CarouselItem>
  ));
};
