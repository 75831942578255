import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { CampaignsWithDetailsDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import { DataOverviewActivity } from "../DataOverviewActivity/DataOverviewActivity";

export interface AppActivityTabInterface {
  campaign: CampaignsWithDetailsDatum;
  onlyCards: boolean;
}

export const AppActivityTab = ({
  campaign,
  onlyCards,
}: AppActivityTabInterface) => {
  const activities = campaign.badges?.data[0]?.activities?.data ?? [];
  return (
    <TabsContent
      value="app_activity"
      className="w-full grid grid-cols-2 gap-4 px-3"
    >
      <DataOverviewActivity
        className="col-span-2"
        onlyCards={onlyCards}
        activities={activities}
      />
    </TabsContent>
  );
};
