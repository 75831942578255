import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { ProductWastesItemsSKU, SKUProductResponse, Sku } from "@/interfaces";
import { dataServices } from "./dataServices";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/atoms/shadcn/ui/use-toast";

function getDataToSKU(arr: SKUProductResponse[]) {
  return arr.flatMap((item: SKUProductResponse, item_idx: number): Sku => {
    return {
      id: item?.id,
      company: item?.enterprise_name ?? undefined,
      company_name: item?.enterprise_name ?? undefined,
      brand: item?.marca ?? undefined,
      sub_brand: item?.submarca ?? undefined,
      sku: item?.sku ?? undefined,
      measure: item?.quantity ?? undefined,
      type_measure: item.measure_unit?.data?.id,
      type_measure_name: item.measure_unit?.data?.symbol,
      id_product_wastes: item.product_wastes?.data[0]?.id ?? undefined,
      waste_id: item.product_wastes?.data[0]?.waste?.data?.id ?? undefined,
      waste_name: item.product_wastes?.data[0]?.waste?.data?.name ?? undefined,
      waste_category_id:
        item.product_wastes?.data[0]?.waste_category?.data?.id ?? undefined,
      waste_category_name:
        item.product_wastes?.data[0]?.waste?.data?.name ?? undefined,
      product_wastes: item.product_wastes?.data.map(
        (uniPW: ProductWastesItemsSKU) => {
          return {
            id_product_wastes: uniPW?.id ?? undefined,
            waste_id: uniPW?.waste?.data?.id ?? undefined,
            waste_name: uniPW?.waste?.data?.name ?? undefined,
            waste_category_id: uniPW?.waste_category?.data?.id ?? undefined,
            waste_category_name: uniPW?.waste_category?.data?.name ?? undefined,
            image: uniPW?.photo ?? undefined,
            _destroy: false,
          };
        }
      ),
      image: item?.image ?? undefined,
    };
  });
}

export const useSkus = (per_page: number) => {
  const { data } = useSession();
  const { toast, dismiss } = useToast();
  const { control, watch } = useForm<{
    globalRange: DateRange;
    search: string;
    type_sku: string;
  }>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
      type_sku: "",
    },
  });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
  } = usePagination(per_page);

  const {
    data: query_table,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ["skus_all", currentPage, watch("type_sku"), pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/products",
            params: {
              per_page: pageSize,
              page: currentPage,
              "include_product_wastes.waste": true,
              "include_product_wastes.waste_category": true,
              include_company: true,
              include_measure_unit: true,
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              search: watch("search") || "",
              type_sku: watch("type_sku") || "",
            },
            token: data?.user?.token || "",
          })
        : null;
      const data_product: Sku[] = getDataToSKU(results.data);
      return { data: data_product, results, meta: { ...results.meta } };
    },
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_products"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/products/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  useEffect(() => {
    if (query_table) {
      setTotalPages(query_table.meta.total_pages);
      setTotal(query_table.meta.total_count);
    }
  }, [query_table]);
  async function forceRefetch() {
    // setCurrentPage(1);
    const idToast = toast({
      variant: "warning",
      description: "Espere un momento, se está cargando la información final",
      title: "Refrescando la información de la página",
    }).id;
    const response = await refetch();
    refetchTotals();
   
    if (response?.data?.data.length !== 0) {
      dismiss(idToast);
      toast({
        variant: "default",
        description: "Datos Actualizados",
        title: "Datos actualizados en la vista",
      });
    } else {
      dismiss(idToast);
      toast({
        variant: "destructive",
        description: "Error al actualizar la página",
        title: "Refresque la página para poder obtener de nuevo los datos.",
      });
    }
  }

  useEffect(() => {
    forceRefetch();
  }, [
    watch("globalRange"),
    watch("search"),
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("type_sku"),
    pageSize,
  ]);

  useEffect(() => {
    forceRefetch();
  }, [
    watch("globalRange"),
    watch("search"),
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("type_sku"),
    pageSize,
  ]);

  return {
    totals,
    query: query_table,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    forceRefetch,
  };
};
