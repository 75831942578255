import { ColumnDef, Row } from "@tanstack/react-table";
import { faker } from "@faker-js/faker";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { RowTicket, Ticket, TicketGridData } from "@/interfaces";
import Image from "next/image";
import { ValidationsImages } from "../molecules";
import { validation_image } from "@/assets";

export const columnTicket = (
  getRowInformation: (data: Ticket) => void
): ColumnDef<Ticket>[] => {
  return [
    {
      accessorKey: "evidence",
      header: "Evidencia",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Image
            src={row.original.evidence ?? validation_image}
            width={100}
            height={100}
            alt={""}
          />
        );
      },
    },
    {
      accessorKey: "user_name",
      header: "Usuario",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "place",
      header: "Place",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => <p>{row.original.place_name}</p>,
    },
    {
      accessorKey: "date_insert",
      header: "Fecha registro",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "sku",
      header: "No. SKU's",
      enableSorting: false,
      cell: ({ row }) => {
        return <p>{row.original.list_sku.length}</p>;
      },
    },
    {
      accessorKey: "details",
      header: "Detalles",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnTicketRejected = (
  getRowInformation: (data: Ticket) => void
): ColumnDef<Ticket>[] => {
  return [
    {
      accessorKey: "user_name",
      header: "Usuario",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => {
        return (
          <div className="flex flex-col text-center">
            <p>{row.original.user_name}</p>
            <p className="text-admins-labels-text">{row.original.id_user}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "place",
      header: "Place",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "date_insert",
      header: "Fecha registro",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "sku",
      header: "No. SKU's",
      enableSorting: false,
      cell: ({ row }) => {
        return <p>{row.original.list_sku.length}</p>;
      },
    },
    {
      accessorKey: "details",
      header: "Detalles",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnsTicketGrid = (
  getIdCell: (row: Row<RowTicket>, idx: number) => void
): ColumnDef<RowTicket>[] => {
  return Array(3)
    .fill(0)
    .map((_, idx) => {
      return {
        header: `scan_rows_${idx}`,
        columns: [
          {
            id: `expand_${idx}_${Math.random() * idx}`,
            cell: ({ row }) =>
              row.original[idx] && (
                <ValidationsImages
                  key={row.original[idx].id_ticket + "_card"}
                  variant={"greenGradient"}
                  image={row?.original[idx]?.evidence}
                  left_text={row.original[idx].sku}
                  right_text={row.original[idx].user_name}
                  onClick={() => getIdCell(row, idx)}
                />
              ),
          },
        ],
      };
    });
};
