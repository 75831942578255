import { DetailedHTMLProps, HTMLAttributes, RefObject, useState } from "react";
import { DialogInfoWasteCenter } from "../DialogInfoWasteCenter/DialogInfoWasteCenter";
import { CollectionCenter } from "@/interfaces/collectionCenterInterface";
import { api } from "@/shared/api";
import { MapRef } from "react-map-gl";
import MapComponent from "@/components/molecules/MapComponent/MapComponent";
import { cn } from "@/lib/utils";

interface CampaignMapProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  collectionCenters: CollectionCenter[];
  mapRef: RefObject<MapRef>;
  campaign_id?: string;
}

export const CampaignMap = ({
  collectionCenters,
  mapRef,
  campaign_id,
  className
}: CampaignMapProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collection_center, setCollection_center] =
    useState<CollectionCenter | null>(null);
  function handleIsOpenDialog(id: string) {
    api
      .get(
        `collection_centers/${id}?include_address=true&include_collection_center_benefit=true&include_wastes=true&include_contacts=true`
      )
      .then((resp: { data: CollectionCenter }) => {
        setCollection_center(resp.data);
      })
      .then(() => {
        setIsOpen(!isOpen);
      });
  }
  function handleCloseDialog() {
    setIsOpen(!isOpen);
  }

  return (
    <section className={cn(className,"min-h-[500px] w-full")}>
      <MapComponent
        mapRef={mapRef}
        collectionCenters={collectionCenters}
        handleDialog={handleIsOpenDialog}
        customMarker={collectionCenters[0]?.pin}
        initialCenter={
          ["36"].some((uni_campaign) => uni_campaign == campaign_id)
            ? {
                latitude: -36.414663957800666,
                longitude: -64.19696194486185,
                zoom: 3,
              }
            : undefined
        }
      />
      <DialogInfoWasteCenter
        collectionCenter={collection_center}
        isOpen={isOpen}
        onClose={handleCloseDialog}
      />
    </section>
  );
};
