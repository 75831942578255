import { Button } from "@/components/atoms/shadcn/ui/button";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { Switch } from "@/components/atoms/shadcn/ui/switch";
import {
  ControlledInput,
  ControlledInputImage,
  SelectWithDefault,
  WasteValidationsContainer,
} from "@/components/molecules";
import { WasteCategoriesId, Waste_Register } from "@/interfaces";
import { Fragment } from "react";
import {
  Control,
  Controller,
  FieldErrors,
} from "react-hook-form";

export const WasteGeneralInfo = ({
  control,
  isRegister,
  wastes_categories,
  errors_waste,
  handleSubmit,
  isSaveData,
}: {
  control: Control<Waste_Register, any>;
  isRegister?: boolean;
  wastes_categories?: WasteCategoriesId[];
  errors_waste: FieldErrors<Waste_Register>;
  handleSubmit: () => void;
  isSaveData?: boolean;
}) => {
  
  return (
    <WasteValidationsContainer title={"Información General"} className="w-full">
      <div className="w-full grid grid-cols-2 gap-7 justify-center p-4">
        <div className="grid grid-cols-2 gap-2">
          <ControlledInputImage
            control={control}
            name_file="file"
            name_image="icon"
          />
          <div>
            <Label className="text-center" htmlFor="type_measure">
              Nombre del residuo
            </Label>
            <ControlledInput
              variant={"adminWhiteBorder"}
              control={control}
              name="name"
            />
          </div>
          <div>
            <Label className="text-center" htmlFor="type_measure">
              Categoría de Residuo
            </Label>
            <Controller
              name={`waste_category_wastes_id`}
              control={control}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <>
                    <SelectWithDefault
                      name={name}
                      realValue={value}
                      setValue={onChange}
                      selectedItems={
                        wastes_categories
                          ? [
                              ...wastes_categories,
                              {
                                id: "new_category",
                                name: "Crear nueva categoría",
                              },
                            ]
                          : [
                              {
                                id: "new_category",
                                name: "Crear nueva categoría",
                              },
                            ]
                      }
                      placeHolder="Categoría de Residuo"
                    />
                    <p className="text-ecolana-wasteRed font-semibold">
                      {errors_waste?.waste_category_wastes_id
                        ? errors_waste?.waste_category_wastes_id?.message
                        : null}
                    </p>
                  </>
                );
              }}
            />
          </div>
        </div>
        {!isRegister && (
          <div className="overflow-y-auto max-h-96 grid grid-cols-2 justify-center text-center items-center">
            <p className="">Campaña / Cliente</p>
            <p className="">Sección</p>
            <Separator orientation="horizontal" className=" col-span-2" />
            {[1, 2, 3, 4].map((uni_campaign, idx) => (
              <Fragment key={`${idx}_campaign`}>
                <p className="">Sabormex</p>
                <p className="">Escaner</p>
                <Separator orientation="horizontal" className=" col-span-2" />
              </Fragment>
            ))}
          </div>
        )}
        <div className=" grid grid-cols-2 gap-5 px-5 py-2 mt-5">
          <Label className="col-span-2">Secciones visibles</Label>
          <div className="grid grid-cols-2 gap-4">
            <Switch variantBG={"adminGreen"} />
            <Label>Acopio</Label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Switch variantBG={"adminGreen"} />
            <Label>Escaner</Label>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Switch variantBG={"adminGreen"} />
            <Label>Diccionario</Label>
          </div>
        </div>
      </div>
      {isSaveData && (
        <Button
          variant={"adminGreen"}
          className="justify-self-center text-xl mt-5"
          onClick={handleSubmit}
        >
          Guardar
        </Button>
      )}
    </WasteValidationsContainer>
  );
};
