import { useMemo, useState } from "react";

export interface PaginationInterface {
  currentPage: number;
  firstPage: () => void;
  lastPage: () => void;
  nextPage: () => void;
  prevPage: () => void;
  paginationLabel: string;
  last_page: number;
  onChangePageSize: (num_rows: number) => void;
  pageSize: number;
}

export const usePagination = (per_page: number) => {
  const [pageSize, setPageSize] = useState(per_page);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const firstPage = () => {
    setCurrentPage(1);
  };
  const lastPage = () => {
    setCurrentPage(totalPages);
  };
  const onChangePageSize = (num_rows: number) => {
    setPageSize(num_rows);
  };

  const paginationLabel = useMemo(
    () =>
      `${currentPage > 1 ? currentPage * pageSize - pageSize + 1 : 1} to ${
        currentPage * pageSize > total ? total : currentPage * pageSize
      } of ${total} results`,
    [currentPage, totalPages, total]
  );
  return {
    currentPage,
    nextPage,
    prevPage,
    firstPage,
    lastPage,
    paginationLabel,
    last_page: totalPages,
    setTotalPages,
    setTotal,
    setCurrentPage,
    pageSize,
    onChangePageSize,
    total,
  };
};
