import { Footer, NavbarLatitudR } from "@/components/organisms";
import { ReactNode } from "react";
import { AvenirNextedium, GothamRounded } from "@/fonts";
import Head from "next/head";
import { logoFullLatitudR } from "@/assets";
import { DataScripts } from "@/components/DataScripts";

export const ViajeDelEnvaseLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Head>
        <title>El Viaje del Envase</title>
        <meta
          name="description"
          content="Únete a este viaje reciclando tus envases de Tetra Pak®."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta name="og:title" content="El Viaje del Envase" />
        <meta
          name="og:description"
          content="Únete a este viaje reciclando tus envases de Tetra Pak®."
        />
        <meta name="og:image" content={logoFullLatitudR.src} />
        <meta name="og:url" content={`https://ecolana.com.mx/latitud-r`} />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="es_MX" />
        <meta name="og:site_name" content="Ecolana" />
        <meta name="twitter:title" content="El Viaje del Envase" />
        <meta
          name="twitter:description"
          content="Únete a este viaje reciclando tus envases de Tetra Pak®."
        />
        <meta name="twitter:image" content={logoFullLatitudR.src} />
        <meta name="twitter:card" content="summary" />
      </Head>
      <main
        className={`flex flex-col min-h-svh w-full bg-[#A9EBE0]  ${AvenirNextedium.variable} ${GothamRounded.variable}
        `}
      >
        <NavbarLatitudR />
        <div className="flex-grow">{children}</div>
        <Footer />
      </main>
      <DataScripts />
    </>
  );
};
