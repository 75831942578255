import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  Waste,
  WasteCategoriesId,
  WasteProperty,
  Waste_Register,
} from "@/interfaces";
import { Control, FieldErrors } from "react-hook-form";
import { WasteGeneralInfo } from "../WasteGeneralInfoCard/WasteGeneralInfoCard";
import { WasteCollectionCenterCard } from "../WasteCollectionCenterCard/WasteCollectionCenterCard";
import { WasteInfoDictionaryCard } from "../WasteInfoDictionaryCard/WasteInfoDictionaryCard";
import { WasteCarousel } from "../WasteCarousel/WasteCarousel";
import { Session } from "next-auth";
import { SelectWithSearch } from "@/components/atoms";

export const WasteInfoTab = ({
  data_session,
  isActive,
  wastes,
  wastes_categories = [],
  wasteProperties = [],
  reset_waste_data,
  control_waste,
  errors_waste,
  handleSaveGeneralInfo,
  handleSaveDictionary,
  handleSaveWasteOnCC,
  active_waste,
  setActive_waste,
}: {
  active_waste: string;
  setActive_waste: (id_waste: string) => void;
  data_session: Session | null;
  isActive: boolean;
  wastes?: Waste[];
  wastes_categories?: WasteCategoriesId[];
  wasteProperties?: WasteProperty[];
  reset_waste_data: (id_waste: string) => void;
  control_waste: Control<Waste_Register, any>;
  errors_waste: FieldErrors<Waste_Register>;
  handleSaveGeneralInfo: () => void;
  handleSaveDictionary: () => void;
  handleSaveWasteOnCC: () => void;
}) => {
  return (
    <TabsContent value={"Info"} className="grid grid-cols-1 gap-5 w-full">
      <SelectWithSearch
        value={active_waste}
        setValue={setActive_waste}
        placeHolder="Residuos"
        selectedItems={wastes}
        className="w-1/3 justify-self-center"
      />
      <WasteCarousel
        wastes={wastes}
        setActive_waste={setActive_waste}
        active_waste={active_waste}
      />
      <WasteGeneralInfo
        control={control_waste}
        wastes_categories={wastes_categories}
        errors_waste={errors_waste}
        handleSubmit={handleSaveGeneralInfo}
        isRegister
        isSaveData
      />
      {active_waste !== "" && isActive && (
        <WasteCollectionCenterCard
          data_session={data_session}
          isActive={isActive}
          waste_id={active_waste}
          isSaveData
        />
      )}
      <WasteInfoDictionaryCard
        wasteProperties={wasteProperties}
        control={control_waste}
        handleSubmit={handleSaveDictionary}
        isSaveData
      />
    </TabsContent>
  );
};
