import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { WasteCategoriesId, WasteProperty, Waste_Register } from "@/interfaces";
import React from "react";
import { Control, FieldArrayWithId, FieldErrors } from "react-hook-form";
import { WasteInfoDictionaryCard } from "../WasteInfoDictionaryCard/WasteInfoDictionaryCard";
import { WasteCollectionCenterCard } from "../WasteCollectionCenterCard/WasteCollectionCenterCard";
import { WasteGeneralInfo } from "../WasteGeneralInfoCard/WasteGeneralInfoCard";
import { Session } from "next-auth";

export const RegisterWasteTab = ({
  data_session,
  isActive,
  wastes_categories = [],
  wasteProperties = [],
  control_waste,
  errors_waste,
  handleSaveCompleteWaste,
}: {
  data_session: Session | null;
  isActive: boolean;
  wastes_categories?: WasteCategoriesId[];
  wasteProperties?: WasteProperty[];
  control_waste: Control<Waste_Register, any>;
  errors_waste: FieldErrors<Waste_Register>;
  handleSaveCompleteWaste: () => void;
}) => {
  return (
    <TabsContent value={"Register"} className="grid grid-cols-1 gap-5 w-full">
      <WasteGeneralInfo
        control={control_waste}
        wastes_categories={wastes_categories}
        errors_waste={errors_waste}
        handleSubmit={() => {}}
        isRegister
      />
      <WasteCollectionCenterCard
        data_session={data_session}
        isActive={isActive}
        isRegister
      />
      <WasteInfoDictionaryCard
        wasteProperties={wasteProperties}
        control={control_waste}
        handleSubmit={handleSaveCompleteWaste}
        isSaveData
      />
    </TabsContent>
  );
};
