import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import {
  AppActivityTab,
  DemographicsTab,
  RewardBadgeTab,
  SearchersTab,
} from "../MarketingTabs";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { BadgeInfoInterface } from "@/interfaces";
import { CampaignsWithDetailsDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import { useEffect, useState } from "react";

interface MarketingOverviewTabsInterface {
  campaign: CampaignsWithDetailsDatum;
}

export const MarketingOverviewTabs = ({
  campaign,
}: MarketingOverviewTabsInterface) => {
  const activeTabs = calculateTabs(campaign);
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    if (!activeTabs.app) {
      setCurrentTab("searches");
    } else {
      setCurrentTab("app_activity");
    }
  }, [campaign]);

  return (
    <Tabs
      className="w-full flex flex-wrap items-center align-middle justify-center gap-5"
      value={currentTab}
      onValueChange={setCurrentTab}
    >
      <div className="flex w-full justify-end lg:mr-10">
        <TabsList className="w-full lg:w-fit h-14 rounded-2xl px-4 space-x-1 lg:space-x-5 lg:min-w-96">
          {activeTabs.app && (
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="app_activity"
            >
              Actividad app
            </TabsTrigger>
          )}
          {!activeTabs.group && activeTabs.app && (
            <>
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="rewards"
              >
                Recompensas
              </TabsTrigger>
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="app_demographics"
              >
                Demográficos App
              </TabsTrigger>
            </>
          )}
          {activeTabs.web && (
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="searches"
            >
              Actividad web
            </TabsTrigger>
          )}
        </TabsList>
      </div>
      {activeTabs.app && (
        <AppActivityTab campaign={campaign} onlyCards={!activeTabs.group} />
      )}
      {!activeTabs.group && (
        <>
          <RewardBadgeTab onlyCards={!activeTabs.group} />
          <DemographicsTab onlyCards={!activeTabs.group} />
        </>
      )}
      {activeTabs.web && <SearchersTab onlyCards={!activeTabs.group} />}
    </Tabs>
  );
};

const calculateTabs = (campaign: CampaignsWithDetailsDatum) => {
  if (
    campaign.campaign_type === "app_web" ||
    campaign.campaign_type === "app"
  ) {
    if (campaign.badges.data[0].activities.data.length > 1) {
      return {
        group: true,
        web: true,
        app: true,
      };
    } else {
      return {
        group: false,
        web: true,
        app: true,
      };
    }
  } else {
    return {
      group: false,
      web: true,
      app: false,
    };
  }
};
