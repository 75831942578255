import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/atoms/shadcn/ui/table";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingFn,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { PaginationFooter } from "../PaginationFooter/PaginationFooter";
import { ReactNode, useEffect, useState } from "react";
import type { PaginationInterface } from "@/hooks";
import {
  ArrowDown01,
  ArrowDown10,
  ArrowDownAZ,
  ArrowDownUp,
  ArrowUpAZ,
} from "lucide-react";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagination: PaginationInterface;
  renderComponent?: ReactNode;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  pagination,
  renderComponent,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    enableMultiRowSelection: false,
    state: {
      sorting,
    },
  });
  // console.log(table.getState().sorting);
  function setPageSizeTable(pageSize: number) {
    table.setPageSize(pageSize);
  }

  return (
    <div className="flex flex-wrap w-full pr-4 transition-all">
      <Table className="overflow-x-visible">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className="text-center font-semibold text-xl"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div
                      className={`${
                        header.column.getCanSort()
                          ? "grid grid-flow-col grid-rows-2"
                          : "flex flex-wrap"
                      }`}
                    >
                      <p className="w-full">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </p>
                      {header.column.getCanSort() && (
                        <div className="flex items-center justify-center">
                          {{
                            asc: <ArrowDownAZ />,
                            desc: <ArrowUpAZ />,
                          }[header.column.getIsSorted() as string] ?? (
                            <ArrowDownUp />
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {renderComponent}
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, idx) => (
              <TableRow
                key={`${row.id}_${idx}`}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell, idx_cell) => (
                  <TableCell
                    className="text-center items-center justify-center w-fit"
                    key={`${cell.id}_${idx}_${idx_cell}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-[300px] text-center text-5xl font-bold text-admins-text-active"
              >
                No se ha encontrado información...
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <PaginationFooter
        setPageSizeTable={setPageSizeTable}
        className="w-full"
        pagination={pagination}
        showPageSize
      />
    </div>
  );
}
