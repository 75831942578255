import { Button } from "@/components/atoms/shadcn/ui/button";
import { CarouselItem } from "@/components/atoms/shadcn/ui/carousel";
import {
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { dataServices, FormFilterGraph } from "@/hooks";
import { BadgeInfoInterface, DataTabType3ObjectType } from "@/interfaces";
import React, { useLayoutEffect, useState } from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { ActivitiesDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import { ComplementData } from "../DataOverviewActivity/ComplementData";
import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";

type GraphsCarousel = {
  [key: string]: {
    [key: string]: {
      name_graphic_data: string;
      nameFilter: string;
      title: string;
      subtitle?: string;
    }[];
  };
};

const GraphsPerSlide: GraphsCarousel = {
  overview: {
    validacion_de_residuos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Kg acumulados",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios Activos",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios con TyC",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Validaciones por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg validados por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg por validación",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. de acopios participantes",
      },
    ],
    escaneo_productos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Total de escaneos",
        subtitle: "*Contempla todos",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Promedio de escaneos por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "SKU's participantes",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "SKU's escaneados",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Residuos escaneados",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Categorias escaneadas",
      },
    ],
    take_photo: [],
  },
  rewards: {
    validacion_de_residuos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Kg acumulados",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios Activos",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios con TyC",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Validaciones por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg validados por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg por validación",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. de acopios participantes",
      },
    ],
    escaneo_productos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios test",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios con TyC",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. de acopios y/o puntos participantes",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. Total Validaciones",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Validaciones por usuario",
      },
    ],
    take_photo: [],
  },
  demographics: {
    validacion_de_residuos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Kg acumulados",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios Activos",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios con TyC",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Validaciones por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg validados por usuario",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Kg por validación",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. de acopios participantes",
      },
    ],
    escaneo_productos: [
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios Activos",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Usuarios con TyC",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. de acopios y/o puntos participantes",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "No. Total Validaciones",
      },
      {
        name_graphic_data: "data_1",
        nameFilter: "globalRange",
        title: "Prom. de Validaciones por usuario",
      },
    ],
    take_photo: [],
  },
};
export const CarouselActivitiesItem = ({
  control,
  activity,
}: {
  control: Control<FormFilterGraph, any>;
  activity: ActivitiesDatum;
}) => {
  const { data: session } = useSession();
  const [currentTab, setCurrentTab] = useState("overview");
  useLayoutEffect(() => {
    setCurrentTab("overview");
  }, [activity]);

  const { data: stats, refetch } = useQuery<{
    data: {
      [key: string]: {
        cards: {
          title: string;
          subtitle?: string;
          value: number;
          graph: string;
        }[];
        graphs: {
          title: string;
          subtitle?: string;
          value: number;
          graph: string;
        }[];
      };
    };
  }>({
    queryKey: ["stats", activity.id + activity.activity_type],
    enabled: session?.user?.token && activity ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await dataServices({
            url: "/activities/" + activity.id + "/stats",
            token: session?.user?.token!,
            deserialize: false,
            params: {
              activity_type: activity.activity_type,
            },
          })
        : null;
    },
  });

  return (
    <CarouselItem className="basis-11/12" key={activity.id} id={activity.id}>
      <WasteValidationsContainer
        title={
          activity.activity_type === "validacion_de_residuos"
            ? "Actividad de reciclaje"
            : activity.activity_type === "escaneo_productos"
            ? "Actividad de escaneo"
            : activity.activity_type === "take_photo" ||
              activity.activity_type === "take_video"
            ? "Actividad de evidencia"
            : activity.activity_type === "general"
            ? "Actividad general"
            : ""
        }
        variant={`${
          activity.activity_type == "validacion_de_residuos"
            ? "activity_general_green"
            : activity.activity_type == "escaneo_productos"
            ? "activity_scan_yellow"
            : activity.activity_type == "take_photo"
            ? "activity_recycling_violet"
            : "activity_evidence_blue"
        }`}
        filter={
          <div className=" bg-slate-100 col-span-2 md:col-span-1 justify-self-center flex items-center align-middle w-fit h-14 justify-between rounded-2xl px-4 ">
            <Button
              onClick={() => {
                setCurrentTab("overview");
              }}
              variant={currentTab == "overview" ? "adminsTab" : "adminGhost"}
              className="font-"
            >
              Overview
            </Button>

            <Button
              onClick={() => {
                setCurrentTab("rewards");
              }}
              variant={currentTab == "rewards" ? "adminsTab" : "adminGhost"}
            >
              Recompensas
            </Button>
            <Button
              onClick={() => {
                setCurrentTab("demographics");
              }}
              variant={
                currentTab == "demographics" ? "adminsTab" : "adminGhost"
              }
            >
              Demográficos
            </Button>
          </div>
        }
      >
        <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-6">
          {stats?.data[currentTab]?.cards?.map(
            (uni_data_map: any, idx: number) => (
              <WasteValidationsContainer
                key={`uni_card_data_${idx}_tab_type_3_${uni_data_map.title}`}
                className={`w-full`}
                title={uni_data_map.title}
                subtitle={uni_data_map.subtitle}
                filter={
                  <FilterPerYearController
                    name={`graphsRanges.${uni_data_map.graph}`}
                    control={control}
                  />
                }
              >
                <CardNumOverview
                  variant={"collection_centers"}
                  className="w-9/12 aspect-square items-center"
                  number={uni_data_map.value}
                />
              </WasteValidationsContainer>
            )
          )}
        </div>
      </WasteValidationsContainer>
      <ComplementData activity={activity} />
    </CarouselItem>
  );
};
