// Generated by https://quicktype.io

// export interface Waste {
//   slug: string;
//   id: string;
//   type: string;
//   waste_informations: WasteInformations;
//   name: string;
//   description: string;
//   active: boolean;
//   platform_visible: string;
//   icon?: string | null;
//   selected: boolean;
//   file?:any
// }

// export interface WasteInformations {
//   data: Datum[];
// }

// export interface Datum {
//   id: string;
//   type: DatumType;
//   waste_property: WasteProperty;
//   info: string | null;
// }

// export enum DatumType {
//   WasteInformation = "waste_information",
// }

// export interface WasteProperty {
//   data: Data;
// }

// export interface Data {
//   id: string;
//   type: DataType;
//   name: string;
// }

// export enum DataType {
//   WasteProperty = "waste_property",
// }

export type WasteId = {
  id: string;
  name: string;
};

export type WasteCategoriesId = {
  id: string;
  name: string;
};

export interface WastePerCategoryID {
  id: string;
  name: string;
  wastes: WasteId[];
}

// export interface WastePropertyInterface {
//   id: string;
//   type: string;
//   name: string;
//   active: boolean;
//   property_data_type: null;
// }

// Generated by https://quicktype.io

export interface Waste {
  id: string;
  type: string;
  waste_informations: WasteInformationData;
  waste_category_wastes: WasteCategoryData;
  waste_category_wastes_id?: string;
  collection_center_wastes: {
    data: {
      id: string;
      type: string;
    }[];
  };
  name: string;
  description: string;
  active: boolean;
  platform_visible: string;
  slug: string;
  icon: string | null;
  selected: boolean;
  file?: string;
}

export interface WasteInformationData {
  data: Datum[];
}

export interface Datum {
  id?: string;
  type?: DatumType;
  waste_property: DataWasteProperty;
  info?: string;
}
export interface WasteCategoryData {
  data: WasteCategoryValues[];
}

export interface WasteCategoryValues {
  id?: string;
  waste_category: {
    data: {
      id: string;
      type: "waste_category";
    };
  };
  _destroy: boolean;
}

export enum DatumType {
  WasteInformation = "waste_information",
}

export interface DataWasteProperty {
  data: WasteProperty;
}

export interface WasteProperty {
  id: string;
  type: DataType;
  name: string;
  active: boolean;
  property_data_type: null;
}

export enum DataType {
  WasteProperty = "waste_property",
}

export interface Waste_Register {
  id?: string;
  type?: string;
  waste_informations: {
    [key: string]: {
      id_waste_info: string;
      data: string | number;
      _destroy: boolean;
    };
  };
  waste_category_wastes: {
    id?: string;
    waste_category_id?: string;
    _destroy: boolean;
  }[];
  waste_category_wastes_id?: string;
  waste_category_name?: string;
  collection_center_wastes: {
    data: {
      id: string;
      type: string;
    }[];
  };
  collection_center_wastes_attributes: {
    id?: string;
    collection_center_id: string;
    _destroy: boolean;
  }[];
  cc_count?: string;
  gp_count?: string;
  name: string;
  description: string;
  active: boolean;
  platform_visible: string;
  slug?: string;
  icon: string | null;
  selected?: boolean;
  file?: string;
}
