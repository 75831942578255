import { cn } from "@/lib/utils";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { CarouselActivitiesOverview } from "../CarouselActivitiesOverview/CarouselActivitiesOverview";
import { ActivitiesDatum } from "@/interfaces/CampaignsWithDetailsInterface";

interface DataPerCardMKTProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onlyCards: boolean;
  activities: ActivitiesDatum[];
}

export const DataOverviewActivity = ({
  className,
  onlyCards,
  activities,
}: DataPerCardMKTProps) => {
  return (
    <div className={cn(className)}>
      <CarouselActivitiesOverview
        activities={activities}
        onlyCards={onlyCards}
      />
    </div>
  );
};
