import { apiBaseURL, apiBaseURLV1 } from "@/constants";
import axios, { AxiosInstance } from "axios";
import Kitsu from "kitsu";

interface IDataServices {
  requestType?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  params?: Object;
  token?: string;
  url: string;
  body?: Object;
  deserialize?: boolean;
  apiVersion?: "v1" | "v2";
  contentType?: "application/json" | "multipart/form-data";
}

export const dataServices = async ({
  requestType = "GET",
  url,
  body,
  params,
  token,
  deserialize = true,
  apiVersion = "v2",
  contentType = "application/json",
}: IDataServices) => {
  const newUrl = `${apiVersion === "v1" ? apiBaseURLV1 : apiBaseURL}`;

  const request =
    requestType === "GET" && deserialize
      ? new Kitsu({
          baseURL: newUrl,
          timeout: 1000000,
          headers: {
            "Content-Type": contentType,
          },
        })
      : axios.create({
          baseURL: newUrl,
          timeout: 1000000,
          headers: {
            "Content-Type": contentType,
          },
        });

  if (token) {
    if (requestType === "GET" && deserialize) {
      (request as Kitsu).axios.defaults.headers.common["Authorization"] = token;
    } else {
      (request as AxiosInstance).defaults.headers.common["Authorization"] =
        token;
    }
  }

  switch (requestType) {
    case "GET":
      return await request.get(url, {
        params,
      });
    case "POST":
      return await request.post(url, body);
    case "PATCH":
      return await request.patch(url, body);
    case "DELETE":
      return await (request as AxiosInstance).delete(url);
    case "PUT":
      return await (request as AxiosInstance).put(url, body);
  }
};
