import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

import { DotIcon } from "lucide-react";
import Link from "next/link";
import { Separator } from "../../atoms/shadcn/ui/separator";
import { fb_url, insta_url, tiktok_url, twitter_url } from "../../../constants";
import { faSquareEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
export const FooterFlex = () => {
  return (
    <footer
      className={`w-full flex flex-col lg:flex-row space-y-3 mx-auto max-w-screen-xl md:justify-between justify-center p-4 mt-10`}
    >
      <div className="text-white w-full md:w-1/2 flex flex-row justify-center gap-5 items-center">
        <Link href="/#comment-form">
          <FontAwesomeIcon icon={faSquareEnvelope} size="xl" />
        </Link>
        <Link href={insta_url}>
          <FontAwesomeIcon icon={faInstagramSquare} size="xl" />
        </Link>
        <Link href={twitter_url}>
          <FontAwesomeIcon icon={faTwitterSquare} size="xl" />
        </Link>
        <Link href={fb_url}>
          <FontAwesomeIcon icon={faFacebookSquare} size="xl" />
        </Link>
        <Link href={tiktok_url}>
          <FontAwesomeIcon icon={faTiktok} size="xl" />
        </Link>
      </div>
      <span className="w-full md:w-1/2 flex flex-row text-sm md:text-sm text-white font-bold sm:text-center dark:text-gray-400 justify-center items-center">
        <Link href="/terminos" className="hover:underline">
          Terminos y condiciones
        </Link>
        <DotIcon />
        <Link href="/aviso" className="hover:underline">
          Aviso de privacidad
        </Link>
      </span>
    </footer>
  );
};
