import { validation_image } from "@/assets";
import { CardBGGradient } from "@/components/atoms";
import { BadgesDataInterface } from "@/interfaces";
import { CampaignsWithDetailsDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import Image from "next/image";

export const BadgeCampaignCard = ({
  campaign,
  isSelected,
}: {
  campaign: CampaignsWithDetailsDatum;
  isSelected: boolean;
}) => {
  const {
    campaignName,
    campaignStartDate,
    campaignEndDate,
    badgeName,
    badgeStartDate,
    badgeEndDate,
    campaignIcon,
    badgeIcon,
  } = calculateData(campaign);
  return (
    <CardBGGradient
      variant={"whiteDisabled"}
      className={`flex w-full px-3 shadow-lg py-2 justify-start min-h-28 items-center ${
        isSelected ? "border-admins-text-active border-2" : "border-none"
      }`}
    >
      {campaign.campaign_type === "app_web" && (
        <>
          <Image
            src={{
              src: badgeIcon,
              width: 100,
              height: 100,
            }}
            alt="campana_insignia"
            className="w-12 h-12 rounded-full overflow-hidden m-1"
          />
          <div className="flex flex-col justify-around w-28">
            <p className="text-admins-text-active text-xs text-left">
              {badgeName}
            </p>
            <p className="text-ecolana-lightGray text-xxs text-left">
              Inicio: {badgeStartDate}
              <br />
              Final: {badgeEndDate}
            </p>
          </div>
        </>
      )}
      <Image
        src={{
          src: campaignIcon,
          width: 100,
          height: 100,
        }}
        alt="campana_insignia"
        className="w-12 h-12 rounded-full overflow-hidden m-1"
      />
      <div className="flex flex-col justify-around">
        <p className="text-admins-text-active text-xs text-left">
          {campaignName}
        </p>
        <p className="text-ecolana-lightGray text-xxs text-left">
          Inicio: {campaignStartDate}
          <br />
          Final: {campaignEndDate}
        </p>
      </div>
    </CardBGGradient>
  );
};

const calculateData = (campaign: CampaignsWithDetailsDatum) => {
  if (campaign.campaign_type === "app_web") {
    return {
      campaignName: campaign?.name,
      campaignStartDate: new Date(campaign.start_date).toLocaleDateString(
        "es-MX"
      ),
      campaignEndDate: new Date(campaign.end_date).toLocaleDateString("es-MX"),
      badgeName: campaign?.badges?.data[0]?.name,
      badgeStartDate: new Date(
        campaign?.badges?.data[0]?.start_date
      ).toLocaleDateString("es-MX"),
      badgeEndDate: new Date(
        campaign?.badges?.data[0]?.end_date
      ).toLocaleDateString("es-MX"),
      campaignIcon:
        campaign.pin ?? "https://cdn.ecolana.com.mx/assets/acopio.png",
      badgeIcon: campaign?.badges?.data[0]?.icon ?? validation_image.src,
    };
  }
  if (campaign.campaign_type === "web") {
    return {
      campaignName: campaign.name,
      campaignStartDate: new Date(campaign.start_date).toLocaleDateString(
        "es-MX"
      ),
      campaignEndDate: new Date(campaign.end_date).toLocaleDateString("es-MX"),
      campaignIcon:
        campaign.pin ?? "https://cdn.ecolana.com.mx/assets/acopio.png",
      badgeIcon: "",
      badgeStartDate: "",
      badgeEndDate: "",
    };
  }
  if (campaign.campaign_type === "app") {
    return {
      campaignName: campaign?.badges?.data[0]?.name,
      campaignStartDate: new Date(
        campaign?.badges?.data[0]?.start_date
      ).toLocaleDateString("es-MX"),
      campaignEndDate: new Date(
        campaign?.badges?.data[0]?.end_date
      ).toLocaleDateString("es-MX"),
      campaignIcon: campaign.badges?.data[0]?.icon ?? validation_image.src,
      badgeIcon: "",
      badgeStartDate: "",
      badgeEndDate: "",
    };
  }
  return {
    campaignName: "",
    campaignStartDate: "",
    campaignEndDate: "",
    badgeName: "",
    badgeStartDate: "",
    badgeEndDate: "",
    campaignIcon: "",
    badgeIcon: "",
  };
};
