import { ColumnDef, Row } from "@tanstack/react-table";
import { faker } from "@faker-js/faker";
import { ValidationsImages } from "../molecules";
import { Scan, SkuScans } from "@/interfaces";

export const columnScans = (): ColumnDef<Scan>[] => {
  return [
    {
      accessorKey: "user_name",
      header: "Usuario",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "date_insert",
      header: "Fecha de registro",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "email",
      header: "Email",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "sku",
      header: "SKU",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "badge",
      header: "Insignia",
      sortingFn: "alphanumericCaseSensitive",
    },
  ];
};

export const columnsScansGrid = (
  getIdCell: (row: Row<[Scan, Scan, Scan]>, idx: number) => void
): ColumnDef<[Scan, Scan, Scan]>[] => {
  return Array(3)
    .fill(0)
    .map((_, idx) => {
      return {
        header: `product_rows_${idx}`,
        columns: [
          {
            id: `expand_${idx}`,
            cell: ({ row }) => {
              return  row.original[idx] && (
                <ValidationsImages
                  key={`${row.original[idx].id}_expand_1_cell`}
                  variant={"greenGradient"}
                  left_text={String(row.original[idx].id)}
                  right_text={row.original[idx].user_name}
                  onClick={() => getIdCell(row, idx)}
                />
              );
            },
          },
        ],
      };
    });
};

export const scanTableData: SkuScans = Array(10)
  .fill(0)
  .map((_, idx) => {
    return {
      id: idx,
      user_name: faker.person.fullName(),
      date_insert: faker.date.future().toISOString(),
      email: faker.internet.email(),
      sku: faker.word.sample(),
      badge: faker.company.name(),
    };
  });
