import { logo_ecolana_green } from "@/assets";
import { Button } from "@/components/atoms/shadcn/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/atoms/shadcn/ui/sheet";
import { LogOutIcon, PanelLeft } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { SideBarOptions } from "./SideBarOptions";
import { signOut, useSession } from "next-auth/react";

export const Sidebar = () => {
  return (
    <>
      <aside
        id="separator-sidebar"
        className="fixed top-0 left-0 z-40 w-64 transition-transform -translate-x-full sm:translate-x-0 flex flex-wrap justify-center  space-y-5 max-h-svh overflow-y-auto"
        aria-label="Sidebar"
      >
        <div className="w-full pt-3 flex justify-center items-center">
          <Image
            className="h-full"
            alt="Logo_ecolana"
            src={logo_ecolana_green}
          />
        </div>
        <SideBarOptions />

        <Button
          onClick={() => {
            signOut({
              callbackUrl: "/auth/login",
              redirect: true,
            });
          }}
          className="flex items-center justify-around px-2 rounded-2xl bg-[#F3F4F6] min-w-[90%] hover:bg-[#F3F4F6]"
          style={{
            color: "hsl(var(--foreground))",
          }}
        >
          <LogOutIcon className="collection_centers_icon" />
          <p className={`text-sm font-semibold w-3/6 font-gotham`}>
            Cerrar sesión
          </p>
        </Button>
      </aside>
      <Sheet>
        <div className="md:hidden w-full flex justify-between px-5 items-center">
          <Link className="h-12 my-2" href={"/admins"}>
            <Image
              className="h-full"
              alt="Logo_ecolana"
              src={logo_ecolana_green}
            />
          </Link>
          <SheetTrigger asChild>
            <Button size="icon" variant="outline" className="sm:hidden">
              <PanelLeft className="h-5 w-5" />
              <span className="sr-only">Toggle Menu</span>
            </Button>
          </SheetTrigger>
        </div>
        <SheetContent
          side="left"
          className="sm:max-w-xs flex flex-col justify-center h-svh space-y-5 overflow-y-auto"
        >
          <SideBarOptions />
        </SheetContent>
      </Sheet>
    </>
  );
};
