import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { cn } from "@/lib/utils";
import AutoHeight from "embla-carousel-auto-height";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import React, { HTMLAttributes, useRef } from "react";
interface CarouselCampaignImagesProps extends HTMLAttributes<HTMLDivElement> {
  pluginFlag?: boolean;
  showBtns?: boolean;
  images?: { img: any }[];
  isBanner?: boolean;
}

export const CarouselCampaignImages = ({
  pluginFlag = false,
  showBtns = true,
  images,
  isBanner,
  className,
}: CarouselCampaignImagesProps) => {
  // const plugin = useRef(Autoplay({ delay: 4000, stopOnInteraction: true }));

  return (
    <section className={cn("px-6 w-full h-fit", className)}>
      <Carousel
        opts={{
          loop: true,
          align: "center",
        }}
        plugins={
          pluginFlag
            ? [Autoplay({ playOnInit: true, delay: 3000 })]
            : []
        }
        className="h-full"
      >
        <CarouselContent
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "-8px",
            marginRight: "8px",
          }}
        >
          {images?.map((uniImage, idxImage) => (
            <CarouselItem
              key={`${idxImage}_imageCarousel`}
              className={`${
                isBanner ? "basis-full" : "basis-6/12"
              }  flex items-center justify-center align-middle pl-2 md:pl-4`}
            >
              <Image alt={`${idxImage}_campaign_data`} src={uniImage.img} />
            </CarouselItem>
          ))}
        </CarouselContent>
        {showBtns && (
          <>
            <CarouselNext />
            <CarouselPrevious />
          </>
        )}
      </Carousel>
    </section>
  );
};
