import { DialogAdmins } from "@/components/atoms";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { CompanyNew, WasteCategoriesId } from "@/interfaces";
import { ControlledInput } from "@/components/molecules";

type DialogNewCategoryProps = {
  isOpen: boolean;
  onClose?: (() => void) | undefined;
  control: Control<WasteCategoriesId, any>;
  errors: FieldErrors<WasteCategoriesId>;
  handleSubmit: () => void;
};

export const DialogNewCategory = ({
  isOpen,
  onClose,
  control,
  errors,
  handleSubmit,
}: DialogNewCategoryProps) => {
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Crear nueva categoría"
      variant={"green"}
    >
      <div className="grid grid-cols-2 gap-4 w-2/3">
        <div className="col-span-2 flex flex-col justify-center gap-2">
          <Label htmlFor="company_name" className=" text-admins-text-active">
            Escribe el nombre de la nueva categoría a crear
          </Label>
          <ControlledInput control={control} name="name" />
        </div>
        <Button
          onClick={handleSubmit}
          variant={"adminGray"}
          className="col-span-2 "
        >
          Guardar
        </Button>
      </div>
    </DialogAdmins>
  );
};
