import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { BadgeInfoInterface, DataGraphicObjectType } from "@/interfaces";
import {
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { Control, UseFormWatch, useWatch } from "react-hook-form";
import { useGraphicsData } from "@/hooks";
import { TableRanking } from "../TableRanking/TableRanking";

const dataGraphicsRewardApp: DataGraphicObjectType = {
  type_1: [
    {
      title: "Stock INICIAL de recompensas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Control de recompensas otorgadas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Recompensas otorgadas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "No. Usuarios VS KG para cumplir recompensas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_2: [
    {
      title: "Stock INICIAL de recompensas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Control de recompensas otorgadas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Recompensas otorgadas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_3: [],
};

const dataCardRewardApp: DataGraphicObjectType = {
  type_1: [
    {
      title: "Total de códigos otorgados",
      subtitle: "*No contempla ecolanitas",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Total de ecolanitas especiales",
      subtitle: "*Especiales: Las que se otorgan por insignia",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Total de ecolanitas ordinarias",
      subtitle: "*Ordinarias: Las que se otorgan por actividad subsecuente",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "No. usuarios con códigos otorgados",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Total de códigos restantes",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_2: [
    {
      title: "Stock INICIAL de recompensas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Control de recompensas otorgadas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_3: [],
};

export interface RewardBadgeTabProps {
  onlyCards: boolean;
}
export const RewardBadgeTab = ({ onlyCards }: RewardBadgeTabProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_recycler",
      "enterprises",
      "brands",
      "waste_categories",
      "wastes",
      "waste_category_enterprises",
      "waste_enterprises",
    ],
    keysYear: [
      "year_history",
      "enterprise_waste_categories",
      "enterprise_wastes",
    ],
  });
  return (
    <TabsContent value="rewards" className="w-full grid grid-cols-2 gap-4 px-3">
      <div className="flex flex-col">
        <p className="text-admins-text-active font-bold">
          Información de la insignia
        </p>
        <Textarea variant={"adminGray"} />
      </div>
      <WasteValidationsContainer className={`col-span-2`} title={"Recompensas"}>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
          {onlyCards &&
            dataCardRewardApp["type_1"]?.map((uni_data, index) => (
              <WasteValidationsContainer
                className={`w-full`}
                key={`uni_bagse_num_${index}`}
                title={uni_data.title}
                filter={
                  <FilterPerYearController
                    name={uni_data.nameFilter}
                    control={control}
                  />
                }
              >
                <CardNumOverview
                  variant={"collection_centers"}
                  className="w-1/2 aspect-square items-center"
                />
              </WasteValidationsContainer>
            ))}
        </div>
      </WasteValidationsContainer>
      {onlyCards &&
        dataGraphicsRewardApp["type_1"]?.map((item, idx) => (
          <WasteValidationsContainer
            key={`graphicCell_${idx * Math.random() * 100}`}
            className={`${
              item.span == 1 ? "" : "col-span-2"
            } bg-admins-centers bg-opacity-15`}
            title={item.title}
            subtitle={item.subtitle}
            filter={
              //   <FilterPerYearController
              //     name={item.nameFilter}
              //     control={control}
              //   />
              <></>
            }
          >
            <OverviewItem
              graphicType={item.graphicType}
              data={
                // generateGraphic(
                //   stats_collection_centers as any,
                //   item.name_graphic_data,
                //   false,
                //   true
                // ) as any
                undefined
              }
            />
          </WasteValidationsContainer>
        ))}
      <TableRanking />
    </TabsContent>
  );
};
