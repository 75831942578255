import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { CarouselCampaignsItem } from "./CarouselCampaignsItem";
import { CampaignsWithDetailsDatum } from "@/interfaces/CampaignsWithDetailsInterface";
import { Dispatch, SetStateAction } from "react";

export const CarouselCampaignsOverview = ({
  campaigns,
  value,
  setValue,
}: {
  campaigns: CampaignsWithDetailsDatum[] | undefined | null;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  return (
    campaigns && (
      <div className="flex w-full justify-center px-3 lg:px-8 items-center">
        <Carousel
          opts={{ loop: false, align: "start" }}
          className="flex w-full"
        >
          <CarouselContent className="-ml-2 md:-ml-4 py-5 px-2 w-full">
            <CarouselCampaignsItem
              campaigns={campaigns}
              value={value}
              setValue={setValue}
            />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
          <CarouselNext className="bg-white fill-white hidden lg:flex" />
        </Carousel>
      </div>
    )
  );
};
