import { ColumnDef } from "@tanstack/react-table";
import { Trash2 } from "lucide-react";
import { Waste_Register } from "@/interfaces";
import { Button } from "../atoms/shadcn/ui/button";
type columnWasteProps = {
  handleOpenCCDialog: (waste: string, type_cc: string) => void;
  handleOpenDeleteDialog: (id: string) => void;
};

export const wastesColumn = ({
  handleOpenDeleteDialog,
  handleOpenCCDialog,
}: columnWasteProps): ColumnDef<Waste_Register>[] => {
  return [
    {
      accessorKey: "name",
      header: "Nombre / ID",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => {
        return (
          <div className="text-center">
            <p className="font-extrabold">{row.original.name}</p>
            <p className="">{row.original.id}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "waste_category_name",
      header: "Categoría",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => {
        return (
          <p>
            {row.original.waste_category_name
              ? row.original.waste_category_name
              : "Sin Categoría"}
          </p>
        );
      },
    },
    {
      accessorKey: "section",
      header: "Sección",
      enableSorting: false,
      cell: ({ row }) => {
        return <p>{row.original.platform_visible}</p>;
      },
    },

    {
      accessorKey: "cc_count",
      header: "Centros de Acopio",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <>
            <p className="font-extrabold">{row.original.cc_count}</p>
            <Button
              onClick={() =>
                handleOpenCCDialog(
                  row.original.id ? row.original.id : "",
                  "centro_acopio"
                )
              }
              variant={"adminUnderlineGhost"}
            >
              {" "}
              Ver Detalle
            </Button>
          </>
        );
      },
    },
    {
      accessorKey: "gp_count",
      header: "Puntos Verdes",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <>
            <p className="font-extrabold">{row.original.gp_count}</p>
            <Button
              onClick={() =>
                handleOpenCCDialog(
                  row.original.id ? row.original.id : "",
                  "punto_verde"
                )
              }
              variant={"adminUnderlineGhost"}
            >
              {" "}
              Ver Detalle
            </Button>
          </>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Acciones",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Trash2
            onClick={() =>
              handleOpenDeleteDialog(row.original.id ? row.original.id : "")
            }
            className="hover:text-admins-button-red cursor-pointer"
          />
        );
      },
    },
  ];
};
