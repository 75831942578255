import * as React from "react";

import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";

export const textAreaVariants = cva("flex min-h-[80px] w-full p-2", {
  variants: {
    variant: {
      default:
        "rounded-md border-2 border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-white placeholder:font-bold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
      adminGray:
        "focus-visible:outline-none ring-offset-transparent bg-admins-input-disabled_bg placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminWhite:
        "focus-visible:outline-none ring-offset-transparent bg-white placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminWhiteBorder:
        "focus-visible:outline-none ring-offset-transparent border border-admins-text-active rounded-xl bg-white placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textAreaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <textarea
        className={cn(textAreaVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
