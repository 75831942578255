import { validation_image } from "@/assets";
import { CardBGGradient } from "@/components/atoms";
import { Waste } from "@/interfaces";
import Image from "next/image";

export const WasteCard = ({
  waste_name,
  waste_category,
  waste_measure,
  waste_icon,
  isSelected,
  onClick,
}: {
  waste_icon?: string;
  waste_name?: string;
  waste_category?: string;
  waste_measure?: string;
  isSelected: boolean;
  onClick?: () => void;
}) => {
  return (
    <CardBGGradient
      variant={"whiteDisabled"}
      className={`flex w-full px-3 gap-2 shadow-lg min-h-24 ${
        isSelected ? "border-admins-text-active border-2" : "border-none"
      }`}
      onClick={onClick}
    >
      <Image
        src={waste_icon ? waste_icon : validation_image}
        alt="waste_icon"
        width={100}
        height={100}
        style={{objectFit: "contain"}}
      />
      <div className="flex flex-col justify-around">
        <p className="text-admins-text-active text-md">{waste_name}</p>
        <p className="text-ecolana-lightGray text-xs">
          Categoría: {waste_category}
        </p>
        <p className="text-ecolana-lightGray text-xs">
          Unidad de medida: {waste_measure}
        </p>
      </div>
    </CardBGGradient>
  );
};
