import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import { useState } from "react";
import {
  GeneralGraphicTab,
  PurchasesGraphTab,
  RecordsGraphTab,
  ScansGraphTab,
  SearchGraphicTab,
  ValidationsGraphTab,
} from "../WasteGraphicsTabs";
import { Session } from "next-auth";

export const TabsGraphicsAnalysis = ({
  session,
  isActive,
  waste_id,
}: {
  session: Session | null;
  isActive: boolean;
  waste_id: string;
}) => {
  const [activeGraphTab, setActiveGraphTab] = useState("General");
  return (
    <Tabs value={activeGraphTab} onValueChange={setActiveGraphTab}>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 items-end z-30 py-5 px-10 w-full">
        <div className="col-span-2 lg:col-span-4 flex h-full items-end justify-end w-full">
          <TabsList className="w-full h-14 justify-between rounded-2xl px-4 space-x-5">
            <TabsTrigger
              variant={"adminTabGray"}
              className="w-1/2"
              value="General"
            >
              General
            </TabsTrigger>
            <TabsTrigger
              variant={"adminTabBlueNavy"}
              className="w-1/2"
              value="Searches"
            >
              Búsquedas
            </TabsTrigger>
            <TabsTrigger
              variant={"adminTabYellow"}
              className="w-1/2"
              value="Validations"
            >
              Validaciones
            </TabsTrigger>
            <TabsTrigger
              variant={"adminTabRed"}
              className="w-1/2"
              value="Scans"
            >
              Escaner
            </TabsTrigger>
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="Purchases"
            >
              Compras
            </TabsTrigger>
            <TabsTrigger
              variant={"adminTabPurple"}
              className="w-1/2"
              value="Records"
            >
              Registro
            </TabsTrigger>
          </TabsList>
        </div>
      </div>
      {activeGraphTab == "General" && isActive && (
        <GeneralGraphicTab
          session={session}
          isActive={activeGraphTab == "General" && isActive}
          waste_id={waste_id}
        />
      )}
      {activeGraphTab == "Searches" && isActive && (
        <SearchGraphicTab
          session={session}
          isActive={activeGraphTab == "Searches" && isActive}
          waste_id={waste_id}
        />
      )}

      {activeGraphTab == "Validations" && isActive && (
        <ValidationsGraphTab
          session={session}
          isActive={activeGraphTab == "Validations" && isActive}
          waste_id={waste_id}
        />
      )}
      {activeGraphTab == "Scans" && isActive && (
        <ScansGraphTab
          session={session}
          isActive={activeGraphTab == "Scans" && isActive}
          waste_id={waste_id}
        />
      )}
      {activeGraphTab == "Purchases" && isActive && (
        <PurchasesGraphTab
          session={session}
          isActive={activeGraphTab == "Purchases" && isActive}
          waste_id={waste_id}
        />
      )}
      {activeGraphTab == "Records" && isActive && (
        <RecordsGraphTab
          session={session}
          isActive={activeGraphTab == "Records" && isActive}
          waste_id={waste_id}
        />
      )}
    </Tabs>
  );
};
