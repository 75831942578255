export { CardPhoneOptions } from "./CardPhoneOptions/CardPhoneOptions";
export { CardsCampaigns } from "./CardsCampaigns/CardsCampaigns";
export { CarouselPress } from "./CarouselPress/CarouselPress";
export { CarouselRecycling } from "./CarouselRecycling/CarouselRecycling";
export { ContactForm } from "./ContactForm/ContactForm";
export { DialogInfoWasteCenter } from "./DialogInfoWasteCenter/DialogInfoWasteCenter";
export { FiltersSheet } from "./FiltersSheet/FiltersSheet";
export { Footer } from "./Footers/Footer";
export { Navbar } from "./Navbar/Navbar";
export { AddCompanyBottles } from "./AddCompanyBottles/AddCompanyBottles";
export { ServiceTable } from "./ServicesTable/ServiceTable";
export { CampaignTabs } from "./CampaignTabs/CampaignTabs";
export { CampaignMap } from "./CampaignMap/CampaignMap";
export { NavbarLatitudR } from "./Navbar/NavBarLatitudR";
export { FooterLatitudR } from "./Footers/FooterLatitudR";
export { FormSectionLatituR } from "./FormSectionLatituR/FormSectionLatituR";
export { StepsLatitudR } from "./StepsLatitudR/StepsLatitudR";
export { NavbarRe } from "./Navbar/NavBarRe";
export { FooterRe } from "./Footers/FooterRe";
export { FormReFilters } from "./FormReFilters/FormReFilters";
export * from "./EscanerTabs";
export { FormValidations } from "./FormValidations/FormValidations";
export * from "./TableRowExpanded/TableRowExpanded";
export * from "./ComprasTabs";
export * from "./ProductTabs";
export { FormProductsValidation } from "./FormProductsValidations/FormProductsValidation";
export { OverviewItem } from "./OverviewItem/OverviewItem";
export * from "./CollectionCentersTabs";
export { FormCCValidation } from "./FormCCValidation/FormCCValidation";
export { FilterSideBar } from "./FilterSideBar/FilterSideBar";
export { DialogCommentHistoryCC } from "./DialogCommentHistoryCC/DialogCommentHistoryCC";
export { FormCreateCC } from "./FormCreateCC/FormCreateCC";
export { FormCreateCollector } from "./FormCreateCollector/FormCreateCollector";
export { DialogDetailedInfoCC } from "./DialogDetailedInfoCC/DialogDetailedInfoCC";
export { DialogNewSchedule } from "./DialogNewSchedule/DialogNewSchedule";
export { DialogResponseNewCC } from "./DialogResponseNewCC/DialogResponseNewCC";
export { NavbarPapalote } from "./Navbar/NavbarPapalote";
export { CarouselCampaignsOverview } from "./CarouselCampaignsOverview/CarouselCampaignsOverview";
export { MarketingOverviewTabs } from "./MarketingOverviewTabs/MarketingOverviewTabs";
export { DataOverviewActivity } from "./DataOverviewActivity/DataOverviewActivity";
export { FilterPerWeekMonthController } from "./FilterPerWeekMonthController/FilterPerWeekMonthController";
export { DialogCCListMKT } from "./DialogCCListMKT/DialogCCListMKT";
export { FormValidationMKT } from "./FormValidationMKT/FormValidationMKT";
export {
  WasteInfoTab,
  RegisterWasteTab,
  InventoryWasteTab,
  OverviewWasteTab,
  TabsGraphicsAnalysis,
} from "./WastesTab";
export { WasteGeneralInfo } from "./WasteGeneralInfoCard/WasteGeneralInfoCard";
export { WasteCollectionCenterCard } from "./WasteCollectionCenterCard/WasteCollectionCenterCard";
export { WasteCarousel } from "./WasteCarousel/WasteCarousel";
export { DialogNewCategory } from "./DialogNewCategory/DialogNewCategory";
export * from "./WasteGraphicsTabs";
export { FooterFlex } from "./Footers/FooterFlex";
