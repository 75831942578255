import { InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { Input, inputVariants } from "../../atoms/shadcn/ui/input";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import {
  Textarea,
  textAreaVariants,
} from "@/components/atoms/shadcn/ui/textarea";
export type Props<T extends FieldValues> = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "name"
> &
  VariantProps<typeof textAreaVariants> & {
    error?: string;
    label?: string;
    control: Control<T, Object>;
    name: Path<T>;
    isEnable?: boolean;
    errors?: string;
    validation?: (value: string) => boolean | string;
  };
export const ControlledTextArea = <T extends FieldValues>({
  error,
  label,
  name,
  control,
  isEnable = true,
  variant,
  placeholder,
  className,
  errors,
  validation,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let errors_validation: string | undefined = undefined;
        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const value = e.target.value;
          // Si hay validación desde props, la ejecutamos
          if (validation) {
            const validationResult = validation(value);
            if (typeof validationResult === "string") {
              // Si la validación falla, disparar el error
              errors_validation = validationResult;
            } else if (validationResult === false) {
              // Si el valor no es válido, retornamos sin hacer el setValue
              return;
            } else {
              errors_validation = undefined;
              // Si la validación pasa, actualizar el valor del campo
              field.onChange(value);
            }
          } else {
            errors_validation = undefined;
            // Si la validación pasa, actualizar el valor del campo
            field.onChange(value);
          }
        };
        return (
          <div className={cn(className, "flex flex-col")}>
            <Textarea
              {...props}
              name={field.name}
              value={field.value}
              onChange={handleChange}
              variant={variant}
              placeholder={placeholder}
            />
            <p className="text-ecolana-wasteRed font-semibold">
              {error?.message} {errors_validation}
            </p>
          </div>
        );
      }}
    />
  );
};
