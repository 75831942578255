import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {  Waste } from "@/interfaces";
import React, { useState } from "react";
import { WasteCarousel } from "../WasteCarousel/WasteCarousel";
import { TabsGraphicsAnalysis } from "./TabsGraphicsAnalysis";
import { Session } from "next-auth";
import { SelectWithSearch } from "@/components/atoms";

export const OverviewWasteTab = ({
  isActive,
  wastes,
  session,
}: {
  isActive: boolean;
  wastes: Waste[];
  session: Session | null;
}) => {
  const [active_waste, setActive_waste] = useState(wastes[0].id || "");
  return (
    <TabsContent value={"Analysis"} className="grid grid-cols-1 gap-5 w-full">
      <SelectWithSearch
        value={active_waste}
        setValue={setActive_waste}
        placeHolder="Residuos"
        selectedItems={wastes}
        className="w-1/3 justify-self-center"
      />
      <WasteCarousel
        wastes={wastes}
        active_waste={active_waste}
        setActive_waste={setActive_waste}
      />
      <TabsGraphicsAnalysis
        isActive={isActive}
        session={session}
        waste_id={active_waste}
      />
    </TabsContent>
  );
};
