import { CarouselItem } from "@/components/atoms/shadcn/ui/carousel";
import { WasteCard } from "@/components/molecules";
import { Waste } from "@/interfaces";

export const WastesItems = ({
  wastes = [],
  setActive_waste,
  active_waste,
}: {
  wastes?: Waste[];
  setActive_waste: (id_waste: string) => void;
  active_waste: string;
}) => {
  return wastes.map((uni_waste, index) => (
    <CarouselItem
      className={`basis-full xl:basis-1/4 lg:basis-1/3 md:basis-1/2 mb-5`}
      key={`CarouselItem_${index * Math.random() * 100}`}
    >
      <WasteCard
        key={`badge_card_${index}`}
        waste_icon={uni_waste.icon !== null ? uni_waste.icon : undefined}
        waste_name={uni_waste.name}
        waste_category={""}
        waste_measure={""}
        isSelected={uni_waste.id == active_waste}
        onClick={()=>setActive_waste(uni_waste.id)}
      />
    </CarouselItem>
  ));
};
