import { Button } from "@/components/atoms/shadcn/ui/button";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { WasteValidationsContainer } from "@/components/molecules";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { Waste, WasteCategoriesId, WasteProperty } from "@/interfaces";
import { useEffect, useState } from "react";

export const WasteOverviewCard = ({
  wastes = [],
  wastes_categories = [],
}: {
  wastes?: Waste[];
  wastes_categories?: WasteCategoriesId[];
  wasteProperties?: WasteProperty[];
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    ""
  );

  return (
    <WasteValidationsContainer title={"Overview"} className="w-full">
      <div className="grid grid-cols-2 gap-5">
        <WasteValidationsContainer
          title={"No. Residuos por sección"}
          className="w-full"
        >
          <OverviewItem
            graphicType={"doughnut"}
            data={undefined}
            //   {selecDataStats({
            //     graphicType: item.graphicType,
            //     name_graphic_data: item.name_graphic_data,
            //     variant: item.variant,
            //   })}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title={"No. de Categorías por sección"}
          className="w-full"
        >
          <OverviewItem
            graphicType={"horizontal_bar_stacked"}
            data={undefined}
            //   {selecDataStats({
            //     graphicType: item.graphicType,
            //     name_graphic_data: item.name_graphic_data,
            //     variant: item.variant,
            //   })}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title={"Residuos por Categorías"}
          className="col-span-2"
        >
          <div className="flex flex-col justify-center align-middle">
            <div className="flex justify-self-center mb-4 border-b border-gray-200 dark:border-gray-700">
              <div className="flex flex-wrap w-full -mb-px text-sm font-medium text-center justify-around space-x-4">
                {wastes_categories?.map((uni_wc, idx_wc) => (
                  <Button
                  key={`${idx_wc}_${uni_wc.name}`}
                    variant={
                      selectedCategory == uni_wc.id
                        ? "adminUnderlineGreen"
                        : "adminGhost"
                    }
                    onClick={() => setSelectedCategory(uni_wc.id)}
                  >{`${uni_wc.name}`}</Button>
                ))}

                <Button
                  variant={
                    selectedCategory == undefined
                      ? "adminUnderlineGreen"
                      : "adminGhost"
                  }
                  onClick={() => setSelectedCategory(undefined)}
                >
                  Sin Categoría
                </Button>
              </div>
            </div>
            <div
              id="default-tab-content"
              className=" grid grid-rows-3 grid-flow-col gap-5 max-w-[70svw] overflow-x-auto overflow-y-hidden"
            >
              {wastes
                ?.filter(
                  (uniWaste, idx_w) =>
                    uniWaste?.waste_category_wastes?.data[0]
                      ?.waste_category.data.id == selectedCategory
                )
                .map((uni_w, idx_w) => (
                  <Button
                    variant={"adminUnderlineGreen"}
                    className="h-24 text-sm"
                    key={`${idx_w}_waste`}
                  >
                    {`${uni_w.name}`}
                  </Button>
                ))}
            </div>
          </div>
        </WasteValidationsContainer>
      </div>
    </WasteValidationsContainer>
  );
};
