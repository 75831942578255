import { ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "@/components/atoms/shadcn/ui/button";
import {
  RowCCValidations,
  collectionCenterValidations,
} from "@/interfaces";
import Image from "next/image";
import { ValidationsImages } from "../molecules";
import { validation_image } from "@/assets";

export const columnCCValidations = (
  getRowInformation: (data: collectionCenterValidations) => void
): ColumnDef<collectionCenterValidations>[] => {
  return [
    {
      accessorKey: "evidence",
      header: "Evidencia",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Image
            src={row.original.evidence ?? validation_image}
            width={100}
            height={100}
            alt={""}
          />
        );
      },
    },
    {
      accessorKey: "user_name",
      header: "Usuario",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original.user_name}</p>
          <p>{row.original.id_user}</p>
        </div>
      ),
    },

    {
      accessorKey: "name_collection_center",
      header: "Nombre de centro",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "date_insert",
      header: "Fecha registro",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "sku",
      header: "No. de Residuos",
      enableSorting: false,
      cell: ({ row }) => {
        return <p>{row.original.wastes_validations.length}</p>;
      },
    },
    {
      accessorKey: "details",
      header: "Detalles",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnCCValidationsRejected = (
  getRowInformation: (data: collectionCenterValidations) => void
): ColumnDef<collectionCenterValidations>[] => {
  return [
    {
      accessorKey: "user_name",
      header: "Usuario",
      sortingFn: "alphanumericCaseSensitive",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original.user_name}</p>
          <p>{row.original.id_user}</p>
        </div>
      ),
    },

    {
      accessorKey: "name_collection_center",
      header: "Nombre de centro",
      sortingFn: "alphanumericCaseSensitive",
    },
    {
      accessorKey: "date_insert",
      header: "Fecha registro",
      sortingFn: "alphanumericCaseSensitive",
    },

    {
      accessorKey: "sku",
      header: "No. SKU's",
      enableSorting: false,
      cell: ({ row }) => {
        return <p>{row.original.wastes_validations.length}</p>;
      },
    },
    {
      accessorKey: "details",
      header: "Detalles",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnsCCValidationsGrid = (
  getIdCell: (row: Row<RowCCValidations>, idx: number) => void
): ColumnDef<RowCCValidations>[] => {
  return Array(3)
    .fill(0)
    .map((_, idx) => {
      return {
        header: `scan_rows_${idx}`,
        columns: [
          {
            id: `expand_${idx}_${Math.random() * idx}`,
            cell: ({ row }) =>
              row.original[idx] && (
                <ValidationsImages
                  key={row.original[idx].id_cc_validation + "_card"}
                  variant={"greenGradient"}
                  image={row.original[idx]?.evidence || validation_image}
                  left_text={row.original[idx].id_user}
                  right_text={row.original[idx].name_collection_center}
                  onClick={() => getIdCell(row, idx)}
                />
              ),
          },
        ],
      };
    });
};
