import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { DataTable, DialogConfirmation } from "@/components/molecules";
import { Waste, WasteCategoriesId, Waste_Register } from "@/interfaces";
import React, { useEffect, useState } from "react";
import { WasteOverviewCard } from "../WasteOverviewCard/WasteOverviewCard";
import { wastesColumn } from "@/components/columns";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import {
  dataServices,
  useCCInfoTable,
  usePagination,
  useTransactionStates,
} from "@/hooks";
import { DialogCCListMKT } from "../DialogCCListMKT/DialogCCListMKT";

export const InventoryWasteTab = ({
  wastes = [],
  wastes_categories = [],
  data_session,
  isActive,
}: {
  wastes?: Waste[];
  wastes_categories?: WasteCategoriesId[];
  data_session: Session | null;
  isActive: boolean;
}) => {
  const [waste_id, setWaste_id] = useState("");
  const [type_cc, setType_cc] = useState("centro_acopio");

  const { isOpen, handleCloseDialog, isOpenConfirm, handleCloseConfirm } =
    useTransactionStates<Waste_Register>({
      resetData: () => {},
    });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
    total,
  } = usePagination(10);

  const { data: wastes_table, refetch: refetch_wastes } = useQuery({
    queryKey: ["wastes_inventory", currentPage, pageSize],
    enabled: data_session?.user?.token && isActive ? true : false,
    queryFn: async () => {
      const results = data_session?.user?.token
        ? await dataServices({
            url: "/wastes",
            params: {
              per_page: pageSize,
              page: currentPage,
              show_inactive: true,
              "include_waste_category_wastes.waste_categories": true,
              sort: "name",
            },
            apiVersion: "v2",
            token: data_session?.user?.token ?? undefined,
          })
        : null;
      const response: Waste_Register[] = await Promise.all(
        results.data.map(async (uni_waste: Waste) => {
          const cc_per_waste = await dataServices({
            url: "/collection_centers",
            params: {
              per_page: 1,
              page: 1,
              show_inactive: false,
              "waste_ids[]": uni_waste.id,
              center_type: "centro_acopio",
            },
            token: data_session?.user?.token ?? undefined,
          });

          const gp_per_waste = await dataServices({
            url: "/collection_centers",
            params: {
              per_page: 1,
              page: 1,
              show_inactive: false,
              "waste_ids[]": uni_waste.id,
              center_type: "punto_verde",
            },
            token: data_session?.user?.token ?? undefined,
          });
          // console.log({meta: gp_per_waste.meta})

          return {
            ...uni_waste,
            gp_count: gp_per_waste.meta.total_count,
            cc_count: cc_per_waste.meta.total_count,
            waste_category_name: uni_waste.waste_category_wastes.data[0]
              ? wastes_categories.find(
                  (uni_category) =>
                    uni_category.id ==
                    uni_waste.waste_category_wastes.data[0]?.waste_category.data
                      .id
                )?.name
              : undefined,
          };
        })
      );
      // console.log({ waste_complete: response });
      return { data: response, meta: { ...results.meta } };
    },
  });

  useEffect(() => {
    if (wastes_table) {
      setTotalPages(wastes_table.meta.total_pages);
      setTotal(wastes_table.meta.total_count);
    }
  }, [wastes_table]);

  const {
    requestData: cc_per_waste,
    pagination: cc_pagination,
    forceRefetchCCInventory: cc_forceRefetchCCInventory,
  } = useCCInfoTable({
    per_page: 10,
    waste_id: waste_id,
    type_cc: type_cc,
    isActive: true,
  });

  return (
    <TabsContent value={"Inventory"} className="grid grid-cols-1 gap-5 w-full">
      <WasteOverviewCard
        wastes={wastes}
        wastes_categories={wastes_categories}
      />
      <DataTable
        pagination={{
          currentPage,
          firstPage,
          lastPage,
          nextPage,
          prevPage,
          paginationLabel,
          last_page,
          onChangePageSize,
          pageSize,
        }}
        columns={wastesColumn({
          handleOpenCCDialog: (waste_id: string, type_cc: string) => {
            setWaste_id(waste_id);
            setType_cc(type_cc);
            cc_forceRefetchCCInventory();
            handleCloseDialog();
          },
          handleOpenDeleteDialog: (id_waste: string) => {},
        })}
        data={wastes_table?.data ? wastes_table.data : []}
      />
      <DialogCCListMKT
        isOpen={isOpen}
        onClose={handleCloseDialog}
        cc_data={cc_per_waste}
        pagination={cc_pagination}
      />
      <DialogConfirmation
        handleSubmit={() => {}}
        isOpen={isOpenConfirm}
        onClose={handleCloseConfirm}
      />
    </TabsContent>
  );
};
