import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../atoms/shadcn/ui/carousel";
import {
  Card,
  CardTitle,
  CardFooter,
  CardContent,
} from "../../atoms/shadcn/ui/card";
import { Button, buttonVariants } from "@/components/atoms/shadcn/ui/button";
import Link from "next/link";
import { iconDictionary, iconEnterprises, iconMap } from "../../../assets";
import { FC, useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { useTranslations } from "next-intl";
import Image, { StaticImageData } from "next/image";
import { CDN_URL } from "@/constants";

interface CardPerRecyclingProps {
  idx: number;
}

type dataPerRecyclingProps = {
  title: string;
  imageSrc: string | StaticImageData;
  btnText: string;
  linkRef: string;
  colorBtn:
    | "ecolanaRecyclingLetsLook"
    | "ecolanaRecycling"
    | "ecolanaRecyclingGetAdvice";
}[];

const CardPerRecycling: FC<CardPerRecyclingProps> = ({ idx }) => {
  const t = useTranslations();

  const dataPerRecycling: dataPerRecyclingProps = [
    {
      title: t("carouselRecyclingLetsLookCard"),
      imageSrc: iconMap,
      btnText: t("btnLetsLook"),
      linkRef: "/mapa",
      colorBtn: "ecolanaRecycling",
    },
    {
      title: t("carouselRecyclingCheckItOutCard"),
      imageSrc: iconDictionary,
      btnText: t("btnCheckItOut"),
      linkRef: "/diccionario",
      colorBtn: "ecolanaRecyclingLetsLook",
    },
    {
      title: t("carouselRecyclingGetAdviceCard"),
      imageSrc: iconEnterprises,
      btnText: t("btnGetAdvice"),
      linkRef: "/empresas",
      colorBtn: "ecolanaRecyclingGetAdvice",
    },
  ];

  return (
    <Card
      key={idx}
      className="p-2 m-5 md:w-3/4 w-full min-h-30 transition-all ease-linear duration-500 rounded-3xl shadow-xl"
    >
      <CardTitle className="flex p-1 m-1 text-[#005142] text-lg lg:text-xl font-extrabold text-center justify-items-center justify-center items-center">
        {dataPerRecycling[idx].title}
      </CardTitle>
      <CardContent className="flex lg:p-4 items-center justify-center">
        <div className="flex items-center justify-center w-full aspect-square bg-[#D9D9D9] rounded-full">
          <Image
            className="w-7/12"
            src={dataPerRecycling[idx].imageSrc}
            alt={`${dataPerRecycling[idx].title}`}
          />
        </div>
      </CardContent>
      <CardFooter className="flex lg:p-0 justify-center flex-col w-full align-middle lg:pt-4 lg:pb-2">
        <Link className="w-full" href={`${dataPerRecycling[idx].linkRef}`}>
          <Button
            variant={dataPerRecycling[idx].colorBtn}
            className={`min-w-fit w-full font-extrabold text-md lg:text-md h-10`}
          >
            {dataPerRecycling[idx].btnText}
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

const ColumnItemPerRecycling: FC = () => {
  return [1, 2, 3].map((_, index) => (
    <div
      key={`${index}_div_column`}
      className={`flex items-center justify-center w-1/5 ${
        index == 1 ? "pb-28" : ""
      }`}
    >
      <CardPerRecycling idx={index} />
    </div>
  ));
};

const CarouselItemPerRecycling: FC = () => {
  return [1, 2, 3].map((_, index) => (
    <CarouselItem
      className="basis-full md:basis-1/2 lg:basis-1/3 flex items-center justify-center"
      key={`${index} title`}
    >
      <CardPerRecycling idx={index} />
    </CarouselItem>
  ));
};

export const CarouselRecycling: FC = () => {
  return (
    <section
      className="flex flex-col justify-center w-full justify-items-center transition-all ease-in-out duration-700 bg-[#88C5B3] py-8 max-h-svh"
      style={{
        backgroundImage: `url(${
          CDN_URL + "/assets_web/images/landing/wave-blanca-1.svg"
        }), url(${CDN_URL + "/assets_web/images/landing/wave-blanca-2.svg"})`,
        backgroundSize: "5%, 5%, 10%, 50%, 50px",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat, repeat-x, repeat",
        backgroundPosition: "100% 40%, -1% 80%, center bottom",
      }}
    >
      <h2 className="my-4 text-center text-3xl lg:text-5xl font-extrabold text-white">
        ¡Comencemos a reciclar!
      </h2>
      <div className="hidden lg:flex w-full lg:flex-wrap justify-center items-end space-x-5">
        <ColumnItemPerRecycling />
      </div>
      <div className="flex w-full justify-center px-14 items-center lg:hidden">
        <Carousel
          plugins={[Autoplay({ playOnInit: true, delay: 3000,  })]}
          opts={{
            align: "start",
            loop: true,
          }}
          className="flex w-full"
        >
          <CarouselContent>
            <CarouselItemPerRecycling />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white" />
          <CarouselNext className="bg-white fill-white" />
        </Carousel>
      </div>
    </section>
  );
};
