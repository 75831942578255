import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import { WasteValidationsContainer } from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { dataGraphicsType } from "@/interfaces";
import { DateRange } from "react-day-picker";
import {
  formatData,
  formatParamsForFilters,
} from "@/shared/functions/formData";
import { dataServices } from "@/hooks";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";

type SearchGraphicTabProps = {
  session: Session | null;
  isActive: boolean;
  waste_id: string;
};

const dataGraphSearch: dataGraphicsType = [
  {
    title: "Búsquedas de residuo en App (TOP 10)",
    subtitle: "* Estado",
    nameFilter: "graphsRanges.search_state",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "search_per_state",
  },
  {
    title: "Búsquedas de residuo en App (TOP 10)",
    subtitle: "* Municipio",
    nameFilter: "graphsRanges.search_town",
    span: 1,
    graphicType: "horizontal_bar",
    name_graphic_data: "search_per_town",
  },
  {
    title: "Búsqueda de residuo en App por Género",
    subtitle: "*Contempla solo usuarios únicos",
    nameFilter: "graphsRanges.search_gender",
    span: 1,
    graphicType: "doughnut",
    name_graphic_data: "search_per_gender",
  },
  {
    title: "Búsqueda de residuo en App por Edad",
    subtitle: "*Contempla solo usuarios únicos",
    nameFilter: "graphsRanges.search_age",
    span: 1,
    graphicType: "vertical_bar",
    name_graphic_data: "search_per_age",
  },
];

const generateData = async (
  token: string,
  id: string,
  ranges: {
    [key: string]: DateRange;
  },
  global: DateRange,
  years: {
    [key: string]: string;
  }
) => {
  const { localRanges, localYears } = formatParamsForFilters(
    ranges,
    global,
    years
  );
  toast({
    title: "Se ha solicitado la información",
    description: `Espera un momento en lo que la información es actualizada`,
    variant: "warning",
  });
  const response = await dataServices({
    url: `/wastes/${id}/stats`,
    params: {
      type: "searches",
      ...localRanges,
    },
    token,
  });
  const formattedData = formatData(response.data);
  return { ...formattedData };
};

export const SearchGraphicTab = ({
  session,
  isActive,
  waste_id,
}: SearchGraphicTabProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: ["search_state", "search_town", "search_age", "search_gender"],
    keysYear: ["year_history"],
  });

  const { data: stats, refetch } = useQuery({
    queryKey: ["wastes_searches_overview", waste_id],
    enabled: session?.user?.token && isActive ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await generateData(
            session.user.token,
            waste_id,
            ranges,
            global,
            years
          )
        : null;
    },
  });
  // console.log("stats", stats);
  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="Searches">
      {/* <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-2 px-5 py-2 gap-4 my-14">
        {dataGraphSearch.map((item, idx) => (
          <WasteValidationsContainer
            key={`${item.title}_${idx}`}
            className={`${
              item.span == 1 ? "" : "col-span-1 lg:col-span-2"
            } bg-admins-centers bg-opacity-15`}
            title={`${item.title}`}
            subtitle={item.subtitle}
            infoButton={item.infoButton}
            filter={
              <FilterPerYearController
                name={`${item.nameFilter}`}
                control={control}
              />
            }
          >
            {item.graphicType && (
              <OverviewItem
                graphicType={`${item.graphicType}`}
                data={generateGraphic(stats, item.name_graphic_data)}
              />
            )}
          </WasteValidationsContainer>
        ))}
      </div>
    </TabsContent>
  );
};
