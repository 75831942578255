import { Button } from "@/components/atoms/shadcn/ui/button";
import { Input } from "@/components/atoms/shadcn/ui/input";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import {
  ControlledTextArea,
  WasteValidationsContainer,
} from "@/components/molecules";
import { WasteProperty, Waste_Register } from "@/interfaces";
import { useEffect } from "react";
import { Control, Controller, FieldArrayWithId } from "react-hook-form";

export const WasteInfoDictionaryCard = ({
  control,
  wasteProperties = [],
  handleSubmit,
  isSaveData,
}: {
  control: Control<Waste_Register, any>;
  wasteProperties?: WasteProperty[];
  handleSubmit: () => void;
  isSaveData?: boolean;
}) => {
  const keyTextPerWasteProperties: { [key: string]: string } = {
    unidad_medida: "Unidad de Medida",
    tipo: "Tipo",
    llevarlo: "¿Cómo debo llevarlo al centro de acopio?",
    no_llevarlo: "¿Qué no llevar?",
    reconozco: "¿Cómo lo reconozco?",
    sabiasque: "Sabias qué...",
    ojo: "Ojo",
    agua: "Agua",
    co2: "CO2",
    petroleo: "Petroleo",
    espacio: "Espacio",
    energia: "Energía",
  };

  return (
    <WasteValidationsContainer
      title={"Información para Diccionario"}
      className="w-full"
    >
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6 justify-center p-4">
        <div className="flex-col flex w-full gap-3">
          <Label className=" text-admins-text-active font-bold text-lg">
            Descripción
          </Label>
          <ControlledTextArea
            className="min-h-16"
            control={control}
            name="description"
            variant={"adminWhiteBorder"}
          />
        </div>
        {wasteProperties?.map((uni_wp, idx) => {
          return (
            <Controller
              key={`${uni_wp.id}_${uni_wp.name}_input`}
              name={`waste_informations.${uni_wp.id}.data`}
              control={control}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <div
                    key={`${uni_wp.id}`}
                    className="flex-col flex w-full gap-3"
                  >
                    <Label className=" text-admins-text-active font-bold text-lg">
                      {keyTextPerWasteProperties[uni_wp.name]}
                    </Label>
                    {uni_wp.property_data_type == "string" ? (
                      <Input
                        variant={"adminWhiteBorder"}
                        className="w-full"
                        name={name}
                        value={value ? value : ""}
                        onChange={onChange}
                      />
                    ) : uni_wp.property_data_type == "text" ? (
                      <Textarea
                        variant={"adminWhiteBorder"}
                        className="w-full min-h-16"
                        name={name}
                        value={value ? value : ""}
                        onChange={onChange}
                      />
                    ) : uni_wp.property_data_type == "number" ? (
                      <Input
                        variant={"adminWhiteBorder"}
                        className="w-full"
                        type="number"
                        name={name}
                        value={value ? value : 0}
                        onChange={onChange}
                      />
                    ) : (
                      <Textarea
                        variant={"adminWhiteBorder"}
                        className="w-full min-h-16"
                        name={name}
                        value={value ? value : ""}
                        onChange={onChange}
                      />
                    )}
                  </div>
                );
              }}
            />
          );
        })}
      </div>
      {isSaveData && (
        <Button
          variant={"adminGreen"}
          className="justify-self-center text-xl mt-5"
          onClick={handleSubmit}
        >
          Guardar
        </Button>
      )}
    </WasteValidationsContainer>
  );
};
