import { DialogAdmins } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { columnMKT_CCs } from "@/components/columns";
import { PaginationInterface, } from "@/hooks";
import { RowCCInventory } from "@/interfaces";

type DialogCCListMKTProps = {
  isOpen: boolean;
  onClose: () => void;
  cc_data:
    | {
        data: RowCCInventory;
        meta: any;
      }
    | undefined;
  pagination: PaginationInterface;
};

export const DialogCCListMKT = ({
  isOpen,
  onClose,
  cc_data,
  pagination,
}: DialogCCListMKTProps) => {

  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Centros de Acopio Asociados"
      variant={"green"}
    >
      <div className="w-full flex justify-center items-center px-5 py-8">
        <DataTable
          columns={columnMKT_CCs()}
          data={cc_data ? cc_data.data : []}
          pagination={pagination}
        />
      </div>
    </DialogAdmins>
  );
};
