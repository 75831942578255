import { ButtonHTMLAttributes, memo, useCallback, useState } from "react";
import { Check, Search } from "lucide-react";
import { cn } from "@/lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import { Button } from "../shadcn/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../shadcn/ui/command";
import { States, Towns } from "@/interfaces/statesTownsInterfaces";
import { Waste } from "@/interfaces/wastesInterface";
import { useDebounceCallback } from "usehooks-ts";

export interface SelectWithSearchProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  setValue: (value: string) => void;
  placeHolder: string;
  selectedItems?: { id: string; name: string }[];
  addToList?: boolean;
  inputValue?: string;
  onChangeInput?: (value: string) => void;
}

export function SelectWithSearch({
  value,
  setValue,
  selectedItems = [],
  placeHolder = "",
  className,
  addToList = false,
  inputValue,
  onChangeInput,
}: SelectWithSearchProps) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ id: "", name: "" });
  const deboncedInput = useDebounceCallback(onChangeInput ? onChangeInput : () => {}, 500);
  ;
  const handleSelectOption = ({ id, name }: { id: string; name: string }) => {
    setValue(id);
    if (!addToList) {
      setSelectedOption({ id, name });
    }
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "justify-stretch w-full text-sm text-[#B4B4B4] ",
            className
          )}
        >
          <Search className="mx-2 h-4 w-4 text-green-500 shrink-0 opacity-50" />
          {selectedOption.name !== "" ? selectedOption.name : placeHolder}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: "var(--radix-popover-trigger-width)" }}
        className="p-0 placeholder:text-sm z-50"
      >
        <Command>
          <CommandInput
            placeholder={`Buscando ${placeHolder}`}
            value={inputValue}
            onValueChange={deboncedInput}
          />
          <CommandList>
            <CommandEmpty>{`No encontramos el ${placeHolder.toLowerCase()}`}</CommandEmpty>
            <CommandItemMemo
              key={`default_commandItem`}
              value={""}
              onSelect={() => handleSelectOption({ id: "", name: "" })}
              className="text-[#B4B4B4]"
            >
              <Check
                className={cn(
                  "mr-2 h-4 w-4",
                  value === "" ? "opacity-100" : "opacity-0"
                )}
              />
              {`Seleccione una opción...`}
            </CommandItemMemo>
            {selectedItems.map((uniOption, idx) => (
              <CommandItemMemo
                key={`${uniOption.name}_commandItem_${idx}`}
                value={uniOption.name}
                onSelect={() => handleSelectOption(uniOption)}
                className="text-[#B4B4B4] hover:bg-gray-100 cursor-pointer"
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === uniOption.id ? "opacity-100" : "opacity-0"
                  )}
                />
                {uniOption.name}
              </CommandItemMemo>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

const CommandItemMemo = memo(CommandItem);
