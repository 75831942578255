import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md  font-medium transition-colors disabled:pointer-events-none disabled:opacity-50 text-lg font-extrabold text-center text-wrap h-fit",
  {
    variants: {
      variant: {
        default:
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2  dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
        destructive:
          "bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2  dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
        outline:
          "border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900 dark:border-slate-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2  dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
        secondary:
          "bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2  dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
        ghost:
          "hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2  dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
        link: "text-slate-900 underline-offset-4 hover:underline dark:text-slate-50 ",
        ecolanaPhone:
          "border-4 rounded-[2.5rem] border-[#2cad8f] text-[#2cad8f] bg-white hover:bg-[#2cad8f] hover:text-white h-12 lg:text-xl",
        ecolanaLetsStart:
          "bg-gradient-to-tr from-[#41B59A] to-[#229C79] leading-10 rounded-3xl color-white text-white h-14 lg:text-xl",
        ecolanaRecycling:
          "bg-ecolana-mediumBlue rounded-3xl text-center text-white border-transparent  font-700 lg:text-xl",
        ecolanaRecyclingLetsLook:
          "bg-[#48D3E4] rounded-3xl text-center text-white border-transparent  font-700 lg:text-xl",
        ecolanaRecyclingGetAdvice:
          "bg-[#A7ACFF] rounded-3xl text-center text-white border-transparent  font-700 lg:text-xl",
        ecolanaNavBar:
          "hover:bg-slate-100 hover:text-[#41b59a] text-white lg:text-xl",
        reNavBar: "hover:bg-transparent hover:text-white text-white lg:text-xl",
        ecolanaLearnMore:
          "bg-[#229C79] leading-10 rounded-3xl  font-semibold text-white lg:text-xl",
        ecolanaContactUs:
          "bg-gradient-to-r from-[#1c7d6b] to-[#002f2f] text-white rounded-3xl border-none h-12 lg:text-xl",
        latitudRSolid: "bg-[#19dc69] text-white rounded-[100px] lg:text-xl",
        latitudRGradient:
          "bg-gradient-to-r from-[#19dc69] to-[#19dc6942]   text-white rounded-2xl hover:bg-[#19dc69] lg:text-xl",
        ecolanaPhonev2:
          "border-none text-[#A7ECDC] bg-transparent hover:text-[#229C79]  h-12 text-md lg:text-xl ",
        ecolanaLetsStartv2:
          "bg-[#229C79]  leading-10 rounded-3xl color-white text-white lg:text-xl",
        adminDownload:
          "text-admins-text-active bg-admins-input-disabled_bg flex justify-around hover:text-admins-text-active hover:bg-admins-input-disabled_bg text-sm",
        adminAddWaste:
          "bg-admins-text-active hover:bg-ecolana-wasteGreen text-white text-md flex justify-around font-light rounded-2xl",
        adminGhost:
          "text-admins-text-inactive hover:text-admins-input-placeholder text-md font-medium",
        adminUnderlineGhost:
          "text-admins-text-inactive hover:text-admins-input-placeholder text-md font-light underline underline-offset-2 font-semibold hover:text-admins-text-active ",
        adminValidate:
          "border border-admins-button-green bg-white text-ecolana-black text-center",
        adminPagination:
          "border bg-white rounded-none border-[#D1D5DB] hover:bg-[#E8FFF9] font-medium",
        adminGray:
          "bg-admins-button-gray text-admins-button-green  hover:bg-admins-button-gray text-xs",
        adminGreen:
          "bg-admins-button-green text-white hover:bg-admins-button-green text-xs",
        adminUnderlineGreen:
          "bg-transparent text-admins-button-green border-b-4 border-admins-button-green",
        adminsTab:
          "bg-admins-text-active rounded-2xl text-xl font-medium shadow-sm text-white",
        adminsValidate: "bg-admins-button-green text-white  hover:bg-admins-button-gray text-xs",
        adminsDelete: "bg-admins-button-red text-white  hover:bg-admins-button-gray text-xs",
        adminsReject: "bg-admins-graphics-age_range_4 text-admins-button-green  hover:bg-admins-button-gray text-xs",
        
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        xl: "h-12 lg:h-16",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    const Comp = "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
