import { Button } from "@/components/atoms/shadcn/ui/button";
import {
  ArrowFloatingButton,
  ecolanitaLatitud,
  miniBanner,
  miniBanner2,
  miniBanner3,
  miniBanner4,
} from "../../../assets";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/atoms/shadcn/ui/sheet";
import {
  CustomTag,
  MostSearchedWasteCard,
  SelectWithSearch,
} from "@/components/atoms";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { FilterIcon } from "lucide-react";
import {
  CarouselCampaignImages,
  SelectWithDefault,
} from "@/components/molecules";
import { States, Towns } from "@/interfaces/statesTownsInterfaces";
import { Waste } from "@/interfaces/wastesInterface";
import { CampaignsOptions } from "@/constants/campaignsData";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormFilterValues } from "@/hooks/useMapData";

const imgCarouselBanner = [
  { img: miniBanner },
  { img: miniBanner2 },
  { img: miniBanner3 },
  { img: miniBanner4 },
];

type FiltersSheetProps = {
  states: States[];
  towns: Towns[];
  wastesSortName: Waste[];
  wastesSortVali: Waste[];
  campaigns: CampaignsOptions[];
  control: Control<FormFilterValues>;
  handleSearch: () => void;
};

export const FiltersSheet = ({
  states,
  towns,
  campaigns,
  wastesSortName,
  wastesSortVali,
  control,
  handleSearch,
}: FiltersSheetProps) => {
  return (
    <Sheet key={"filters-sheet"}>
      <SheetTrigger asChild>
        <Button className="w-fit bg-white hover:bg-white text-black font-extrabold py-2 rounded-full shadow-lg text-lg md:text-xl px-5 hidden md:flex md:fixed md:top-28 md:left-14">
          Filtros
          <ArrowFloatingButton className="hidden md:flex w-full" />
        </Button>
      </SheetTrigger>
      <div
        className={`absolute w-full z-[20] min-h-[100px] top-0  transition-opacity ease-out duration-500 overflow-hidden  justify-center items-center bg-[#FFFFFF] p-4 flex flex-row pt-[80px] md:hidden`}
      >
        <div className="w-1/3 justify-evenly flex flex-wrap">
          <h3 className="text-[#2CAD8F] w-full text-center">Más Buscados</h3>
          <Controller
            control={control}
            name="wastes"
            render={({ field: { name, onChange, value } }) => (
              <>
                {wastesSortVali.slice(0, 3).map((waste, index) => (
                  <MostSearchedWasteCard
                    key={`${waste.name}_mostSearched_${index}`}
                    img={waste.icon!}
                    label={waste.name}
                    selected={value.includes(waste.id)}
                    onClick={() => {
                      const newSelected = value.includes(waste.id)
                        ? value.filter((uni) => uni !== waste.id)
                        : [...value, waste.id];
                      onChange(newSelected);
                      setTimeout(() => {
                        handleSearch();
                      }, 1000);
                    }}
                  />
                ))}
              </>
            )}
          />
        </div>
        <Separator className="h-[50px]" orientation="vertical" />
        <div className="w-1/3 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-[#2CAD8F] w-full text-center text-xs">
            Campañas
          </h2>
          <Controller
            name="campaign"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <SelectWithDefault
                value={value}
                name={name}
                setValue={(value) => {
                  onChange(value);
                  setTimeout(() => {
                    handleSearch();
                  }, 1000);
                }}
                selectedItems={campaigns}
                placeHolder="Campañas"
                className="h-8 w-auto mx-2 line-clamp-1"
              />
            )}
          />
        </div>
        <Separator className="h-[50px]" orientation="vertical" />
        <div className="w-1/3 flex justify-center items-center">
          <SheetTrigger asChild>
            <div className="w-full flex flex-col text-center text-[#12675b] justify-center">
              <FilterIcon className="w-full" />
              <p className="w-full text-[0.55rem]">Ver todos los filtros</p>
            </div>
          </SheetTrigger>
        </div>
      </div>
      {/* <FloatingActionButton
          label="Filtros"
          side={"mixed_tl_md_br"}
          variant={"default"}
          iconToShow={
            <>
              <ArrowFloatingButton className="hidden sm:flex w-full" />
              <FilterFloatingButton className="flex sm:hidden w-full" />
            </>
          }
          className="md:bottom-[45rem]"
        />*/}
      <SheetContent
        side={"left"}
        bg={"transparent"}
        className="flex flex-col w-full lg:w-1/3 sm:max-w-full h-full md:pt-24 items-center justify-items-center bg-white overflow-auto text-[#1B2C32]"
        buttonCloseClassName="top-20 md:top-20"
      >
        <div className="w-full text-center font-bold text-sm flex flex-col items-center justify-items-center">
          <h2 className="text-[#2cad8e]">Encuentra un lugar para reciclar</h2>
        </div>
        <Separator />
        <div className="w-full flex flex-col items-center text-center font-bold text-sm space-y-2">
          <h2 className=" text-[#2cad8e] w-3/4">Búsqueda por campañas</h2>
          <Controller
            name="campaign"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <SelectWithDefault
                value={value}
                name={name}
                setValue={onChange}
                selectedItems={campaigns}
                placeHolder="Campañas"
                className="w-3/4"
              />
            )}
          />
        </div>
        <Separator />
        <div className="w-full text-center text-[#2cad8e] font-bold text-sm">
          <h2>Búsqueda por ubicación</h2>
        </div>
        <div className="flex flex-col w-3/4 text-left justify-center text-sm space-y-2 font-bold">
          <h3 className="text-[#1B2C32]">Estado</h3>
          <Controller
            name="state"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <SelectWithSearch
                placeHolder={"Estado"}
                value={value}
                setValue={onChange}
                name={name}
                selectedItems={states}
              />
            )}
          />
        </div>
        <div className=" w-3/4 text-left font-bold text-sm space-y-2">
          <h3 className="text-[#1B2C32]">Municipio</h3>
          <Controller
            name="town"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <SelectWithSearch
                placeHolder={"Municipio"}
                value={value}
                setValue={onChange}
                name={name}
                selectedItems={towns}
              />
            )}
          />
        </div>
        <Separator />
        <div className="w-3/4 text-center font-bold text-sm flex flex-col justify-center items-center space-y-2">
          <h2 className=" text-[#2cad8e] text-center w-full">
            Búsqueda por residuos
          </h2>
          <div className="w-2/3 flex flex-wrap gap-2">
            <h3 className="text-[#2cad8e] font-extralight w-full text-center">
              Más Buscados
            </h3>
            <Controller
              control={control}
              name="wastes"
              render={({ field: { name, onChange, value } }) => (
                <>
                  {wastesSortVali.slice(0, 5).map((waste, index) => (
                    <MostSearchedWasteCard
                      key={`${waste.name}_mostSearched_${index}`}
                      img={waste.icon!}
                      label={waste.name}
                      selected={value.includes(waste.id)}
                      onClick={() => {
                        const newSelected = value.includes(waste.id)
                          ? value.filter((uni) => uni !== waste.id)
                          : [...value, waste.id];
                        onChange(newSelected);
                      }}
                    />
                  ))}
                </>
              )}
            />
          </div>
          <Controller
            name="wastes"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <SelectWithSearch
                placeHolder={"Residuos"}
                name={name}
                addToList
                value=""
                setValue={(select) => {
                  {
                    const newSelected = value.includes(select)
                      ? value.filter((uni) => uni !== select)
                      : [...value, select];
                    onChange(newSelected);
                  }
                }}
                selectedItems={wastesSortName}
              />
            )}
          />
        </div>
        <div className="w-full flex flex-wrap gap-2">
          <Controller
            control={control}
            name="wastes"
            render={({ field: { name, onChange, value } }) => (
              <>
                {wastesSortName
                  .filter((uni_waste) => value.includes(uni_waste.id))
                  .map((uniSelected, index) => (
                    <CustomTag
                      onClick={() =>
                        onChange(value.filter((uni) => uni !== uniSelected.id))
                      }
                      tag2Show={uniSelected.name}
                      key={`${uniSelected.name}_customTag_${index}`}
                    />
                  ))}
              </>
            )}
          />
        </div>
        <Separator />
        <div className="flex w-full justify-center md:justify-center">
          <Button
            onClick={handleSearch}
            className="w-1/3 py-2 bg-ecolana-mediumBlue text-xl font-extrabold rounded-3xl"
          >
            Buscar
          </Button>
        </div>
        {/*<CarouselCampaignImages
          className="min-h-[25svh]"
          images={imgCarouselBanner}
          isBanner={false}
          pluginFlag={true}
          showBtns={false}
        />*/}
      </SheetContent>
    </Sheet>
  );
};
